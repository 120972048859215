import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineCarouselRow from './MagazineCarouselRow'
import ThemeLoader from './ThemeLoader'
import DigimagHero from '../navigation/DigimagHero'

import './MagazineArchive.scss'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import application from '@otavamedia/om-component-library/lib/kea/application'
import { connect } from 'kea'
import headerStore from '@otavamedia/om-component-library/lib/kea/header'
import { RectShape } from 'react-placeholder/lib/placeholders'
import track from 'react-tracking'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'

@connect({
  props: [
    magazineStore, [
      'magazine as contents',
    ],
    headerStore, [
      'menus',
    ],
  ],
  actions: [
    application, [
      'setRendered'
    ],
    magazineStore, [
      'loadMagazine'
    ]
  ],
})
class ThemeMagazineArchive extends Component {
  constructor (props) {
    super(props)

    this.state = {
      openMagazine: this.props.match ? this.props.match.params.number : null,
      highlightsLoaded: false,
      newestMag: null
    }
  }

  async componentDidMount () {
    const magazines = (await WP.getThemeMagazines()).sort((a, b) => a.latest_post_date_gmt < b.latest_post_date_gmt ? 1 : -1)
    const newestMag = await WP.getMagazine(magazines[0].slug, 'thememagazine')
    this.setState({ newestMag })
    this.actions.setRendered(true)
    pushPageDataToGTM({})
  }

  openThemeMagazine = (magazine) => {
    if (!magazine.error) {
      this.props.history.push(`/teemalehti/${magazine.slug}`)
    }
  }

  componentWillUnmount () {
    this.actions.setRendered(false)
  }

  render () {
    const { newestMag } = this.state
    /* eslint-disable jsx-a11y/no-onchange */
    return (
      <Fragment>
        <Helmet>
          <title>
            {`Teemalehdet - ${window.om_constants.siteNameGen}`}
          </title>
        </Helmet>
        <div styleName="main-content-col no-h-padding digimag-archive">
          <div styleName="header-row no-border no-h-padding">
            {newestMag
              ? <DigimagHero digimag={newestMag} toc={true} showTM={false} isThemeMag={true}/>
              : <RectShape style={{ width: '100%', height: 520 }}/>
            }
          </div>
          <MagazineCarouselRow LoaderComponent={ThemeLoader} title="Teemalehdet"
            onMagazineItemClick={this.openThemeMagazine} initialSlide={0} highlightSelected={false} key={'carousel-theme'}
            showIcon={false}/>
        </div>
      </Fragment>
    )
    /* eslint-enable jsx-a11y/no-onchange */
  }
}

ThemeMagazineArchive.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  contents: PropTypes.object,
  setRendered: PropTypes.func,
  menus: PropTypes.array,
}

export default track({ gtmContext: ['ThemeMagazineArchive'] })(withErrorBoundary(
  ThemeMagazineArchive,
  ErrorPlaceholder(null)
))
