import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import whatsapp from '../../assets/ui/whatsapp-white.svg'
import messenger from '../../assets/ui/messenger-white.svg'
import twitter from '../../assets/ui/twitter-white.svg'
import email from '../../assets/ui/email-white.svg'
import copy from '../../assets/ui/copy-white.svg'
import facebookWhite from '../../assets/ui/facebook-white.svg'
import caret from '../../assets/ui/caret-icon.svg'
import caretRed from '../../assets/ui/caret-icon-red.svg'
import caretBlue from '../../assets/ui/caret-icon-blue.svg'
import searchIcon from '../../assets/ui/search-icon.svg'
import chatIcon from '../../assets/ui/chat-icon.svg'
import bugReportIcon from '../../assets/ui/icon_flag.svg'
import closeIcon from '../../assets/ui/close-icon.svg'
import closeIconRed from '../../assets/ui/close-icon-red.svg'
import closeIconBlue from '../../assets/ui/close-icon-blue.svg'
import arrowIcon from '../../assets/ui/arrow-icon.svg'
import arrowIconRed from '../../assets/ui/arrow-icon-red.svg'
import arrowIconPrimary from '../../assets/ui/arrow-icon-blue.svg'
import libraryIcon from '../../assets/ui/library.svg'
import paywallIcon from '../../assets/ui/paywall-icon.svg'
import listIconBlue from '../../assets/ui/list-blue.svg'
import listBulletIcon from '../../assets/ui/list-bullet-icon.svg'
import userIconBlue from '../../assets/ui/user-blue.svg'
import offlineArchiveIcon from '../../assets/ui/archive.svg'
import siteLogo from '../../assets/logos/Era.svg'
import arrowDown from '../../assets/logos/line-2.svg'
import thumbIcon from '../../assets/logos/thumb.svg'
import facebookNoBgLogo from '../../assets/social/facebook-no-bg.svg'
import twitterNoBgLogo from '../../assets/social/twitter-no-bg.svg'
import mailNoBgLogo from '../../assets/social/mail-no-bg.svg'

import facebookLogo from '../../assets/social/facebook-new.svg'
import facebookLogoHover from '../../assets/social/facebook-new-hover.svg'
import instagramLogo from '../../assets/social/instagram-new.svg'
import instagramLogoHover from '../../assets/social/instagram-new-hover.png'
import youtubeLogo from '../../assets/social/youtube-new.svg'
import youtubeLogoHover from '../../assets/social/youtube-new-hover.svg'
import twitterLogo from '../../assets/social/twitter-new.svg'
import twitterLogoHover from '../../assets/social/twitter-new-hover.svg'
import mailLogo from '../../assets/social/mail-new.svg'
import rssLogo from '../../assets/social/rss-new.svg'
import rssLogoHover from '../../assets/social/rss-new-hover.svg'

import styles from './Icons.scss'

class Icon extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    baseStyle: PropTypes.string.isRequired,
    style: PropTypes.string,
    alt: PropTypes.string,
    role: PropTypes.string,
  }

  render () {
    const { src, baseStyle, style = false, alt = '', role, ...rest } = this.props
    return (
      <div className={classnames(styles[baseStyle], style ? styles[style] : false)} styleName='icon' {...rest}>
        <img src={src} alt={alt} role={role}/>
      </div>
    )
  }
}

class IconWithHover extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    hoverSrc: PropTypes.string.isRequired,
    baseStyle: PropTypes.string.isRequired,
    style: PropTypes.string,
    alt: PropTypes.string,
    role: PropTypes.string,
  }

  render () {
    const { src, hoverSrc, baseStyle, style = false, alt = '', role, ...rest } = this.props
    return (
      <div className={classnames(styles[baseStyle], style ? styles[style] : false)} styleName='icon' {...rest}>
        <img src={src} alt={alt} role={role} styleName="no-hover"/>
        <img src={hoverSrc} alt={alt} role={role} styleName="hover"/>
      </div>
    )
  }
}

/* UI */

const Caret = ({ style, color }) => {
  const svg = color === 'red' ? caretRed : (color === 'blue' ? caretBlue : caret)
  return <Icon src={svg} baseStyle='caret-icon' style={style} alt='' role='presentation' />
}

Caret.propTypes = {
  style: PropTypes.string.isRequired,
  color: PropTypes.string,
}

// eslint-disable-next-line react/prop-types
export const CaretRight = ({ color }) => {
  return <span styleName="fa blue">{'\uf105'}</span>
}

// eslint-disable-next-line react/prop-types
export const CaretDown = ({ color }) => {
  return <span styleName="fa blue">{'\uf107'}</span>
}

// eslint-disable-next-line react/prop-types
export const CaretLeft = ({ color }) => {
  return <span styleName="fa blue">{'\uf104'}</span>
}

export const ThumbIcon = () => {
  return <Icon src={thumbIcon} baseStyle='ui-icon' style='thumb-icon' alt='' />
}

export const SearchIcon = () => {
  return <Icon src={searchIcon} baseStyle='ui-icon' style='search-icon' alt='Hae' />
}

export const InfoIcon = () => {
  return <div styleName="fa blue">{'\uf05a'}</div>
}

export const ArrowDown = () => {
  return <Icon src={arrowDown} baseStyle='ui-icon' style='arrow-down' alt='' role='presentation' />
}

export const SearchIconBlue = () => {
  return <div styleName="fa blue">{'\uf002'}</div>
}

export const ChatIcon = () => {
  return <Icon src={chatIcon} baseStyle='ui-icon' alt='Keskustelu' role='presentation' />
}

export const BugReportIcon = () => {
  return <Icon src={bugReportIcon} baseStyle='ui-icon' alt='Raportoi ongelma' role='presentation' />
}

export const CloseIcon = () => {
  return <Icon src={closeIcon} baseStyle='ui-icon' alt='Sulje' role='presentation' />
}

export const CloseIconRed = () => {
  return <Icon src={closeIconRed} baseStyle='ui-icon' alt='Sulje' role='presentation' />
}

export const CloseIconBlue = () => {
  return <Icon src={closeIconBlue} baseStyle='ui-icon' alt='Sulje' role='presentation' />
}

export const ArrowRightIcon = (props = {}) => {
  return <Icon src={arrowIcon} baseStyle='ui-icon' alt='Avaa' {...props} />
}

export const ArrowRightRedIcon = () => {
  return <Icon src={arrowIconRed} baseStyle='ui-icon' alt='Avaa' />
}
export const ArrowRightPrimaryIcon = () => {
  return <Icon src={arrowIconPrimary} baseStyle='ui-icon' alt='Avaa' />
}

export const PreviousArrow = () => {
  return <div styleName="fa blue">{'\uf060'}</div>
}

export const NextArrow = () => {
  return <div styleName="fa blue">{'\uf061'}</div>
}

export const LibraryIcon = () => {
  return <Icon src={libraryIcon} baseStyle='ui-icon' alt='Arkisto' />
}

export const LibraryIconBlue = () => {
  return <div styleName="fa blue">{'\uf02d'}</div>
}

export const ListBulletIcon = () => {
  return <Icon src={listBulletIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

export const PaywallIcon = () => {
  return <Icon src={paywallIcon} baseStyle='ui-icon' alt='' role='presentation' />
}

/* Logos */

export const SiteLogo = () => {
  return <Icon src={siteLogo} baseStyle='rm-icon' alt='' role='presentation' />
}
export const SiteLogoSmall = () => {
  return <Icon src={siteLogo} baseStyle='rm-icon' alt='' role='presentation' />
}

/* Social */

export const FacebookLogo = () => {
  return <IconWithHover src={facebookLogo} hoverSrc={facebookLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const FacebookNoBgLogo = () => {
  return <Icon src={facebookNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const InstagramLogo = () => {
  return <IconWithHover src={instagramLogo} hoverSrc={instagramLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const YoutubeLogo = () => {
  return <IconWithHover src={youtubeLogo} hoverSrc={youtubeLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const TwitterLogo = () => {
  return <IconWithHover src={twitterLogo} hoverSrc={twitterLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}
export const TwitterNoBgLogo = () => {
  return <Icon src={twitterNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const MailLogo = () => {
  return <Icon src={mailLogo} baseStyle='social-icon' alt='' role='presentation'/>
}
export const MailNoBgLogo = () => {
  return <Icon src={mailNoBgLogo} baseStyle='social-icon' alt='' role='presentation'/>
}

export const RssLogo = () => {
  return <IconWithHover src={rssLogo} hoverSrc={rssLogoHover} baseStyle='social-icon' alt='' role='presentation'/>
}

export const SubscriberIcon = () => {
  return (
    <div styleName="subscriber-icon">
      <PaywallIcon />
      <div>Tilaajille</div>
    </div>
  )
}

export const ListIcon = () => {
  return <div styleName="fa blue">{'\uf03a'}</div>
}

export const ListIconBlue = () => {
  return <Icon src={listIconBlue} baseStyle='ui-icon' alt='' role='presentation' />
}

export const UserIcon = () => {
  return <span styleName="fa">{'\uf007'}</span>
}

export const UserIconBlue = () => {
  return <Icon src={userIconBlue} baseStyle='ui-icon' alt='' role='presentation' />
}

export const PasswordIcon = () => {
  return <span styleName="fa">{'\uf023'}</span>
}

export const WhatsappIcon = () => {
  return <Icon src={whatsapp} baseStyle='ui-icon' alt='' role='presentation' />
}

export const MessengerIcon = () => {
  return <Icon src={messenger} baseStyle='ui-icon' alt='' role='presentation' />
}

export const TwitterWhiteIcon = () => {
  return <Icon src={twitter} baseStyle='ui-icon' alt='' role='presentation' />
}

export const EmailWhiteIcon = () => {
  return <Icon src={email} baseStyle='ui-icon' alt='' role='presentation' />
}

export const CopyIcon = () => {
  return <Icon src={copy} baseStyle='ui-icon' alt='' role='presentation' />
}
export const FacebookWhiteIcon = () => {
  return <Icon src={facebookWhite} baseStyle='ui-icon' alt='' role='presentation' />
}

/*
// eslint-disable-next-line react/prop-types
export const TrashIcon = ({ alt = 'Poista', ...rest }) => (
  <Icon src={trashIcon} baseStyle='ui-icon' alt={alt} {...rest} />
)

// eslint-disable-next-line react/prop-types
export const DownloadIcon = ({ alt = 'Tallenna', ...rest }) => (
  <Icon src={downloadIcon} baseStyle='ui-icon' alt={alt} {...rest} />
)
*/
// eslint-disable-next-line react/prop-types
export const OfflineArchiveIcon = ({ alt = 'Offline-arkisto', ...rest }) => (
  <Icon src={offlineArchiveIcon} baseStyle='ui-icon' alt={alt} {...rest} />
)
