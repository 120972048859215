import React, { Component } from 'react'
import ClubForm from './ClubForm'

/**
 * Displays a form for the Fish club
 */
export default class FishForm extends Component {
  render () {
    return <ClubForm formPages={formPages} type="fish" texts={texts}/>
  }
}

const texts = {
  intro: {
    smallTitle: 'Ilmoita saalis',
    mainTitle: 'Kala24-kilpailu',
    ingress: 'Kala24-kilpailu käynnistyi vuoden vaihteessa ja jatkuu 31. joulukuuta 2024 asti. Vuoden aikana kilpailuun on mahdollista ilmoittaa niin monta kalaa kuin ehtii pyytää. Kilpailuun kelpaavat kaikki kalat, kunhan ne on pyydetty Suomesta kalenterivuoden aikana. ',
    text: ''
  },
  outro: {
    mainTitle: 'Kiitos ilmoittautumisestasi!'
  }
}

const formPages = [
  [ // page 1
    {
      topHeader: 'Ilmoita saalis',
      title: 'Yhteystiedot',
      items: [
        {
          name: 'Kalastajan nimi',
          field: 'pyydystaja',
          noLabel: false,
          required: true,
        },
        {
          name: 'Ilmoittajan nimi',
          field: 'ilmoittaja',
          noLabel: false,
          readonly: true,
          default: 'realName',
        },
        {
          name: 'Ilmoittajan sähköpostiosoite',
          field: 'email',
          noLabel: false,
          readonly: true,
          pattern: '^.+@.+\\..+$',
          default: 'email'
        },
        {
          name: 'Kalakilpailuun ilmoitetut saaliit ovat mukana vuoden mittaan suoritettavissa arvonnoissa.\n' +
            'Osallistumisen edellytyksenä on sähköisen suoramarkkinoinnin ja asiakasviestinnän hyväksyminen.\n' +
            'Vastanneiden nimi- ja osoitetietoja voidaan käyttää ja luovuttaa suoramarkkinointitarkoituksiin\n' +
            'henkilötietolain mukaisesti. Lisätietoja Otavamedia Oy:n <a href="https://otavamedia.fi/tietosuoja/" target="_blank">rekisteriselosteesta</a>.',
          type: 'info'
        },
        {
          name: 'Annan suostumukseni sähköiseen suoramarkkinointiin ja asiakasviestintään',
          type: 'checkbox',
          field: 'suostumus',
          required: true,
          noSummary: true
        },
        {
          name: 'Hyväksyn <a href="/kalakilpailun_saannot/" target="_blank">kalakilpailun ja arvonnan säännöt</a>.',
          type: 'checkbox',
          field: 'ehdot',
          required: true,
          noSummary: true
        }
      ],
    },
  ],
  [ // page 2
    {
      topHeader: 'Ilmoita saalis',
      title: 'Saalistiedot',
      items: [
        {
          type: 'row',
          items: [

            {
              name: 'Saantipäivä',
              field: 'pyyntipvm',
              type: 'date',
              required: true,
              style: { width: '50%' },
            },
            {
              name: 'Kellonaika',
              field: 'pyyntiaika',
              style: { width: '50%' },
              pattern: '^[0-9]{1,2}[.:][0-9]{1,2}$',
              match: '.',
              replace: ':'
            },
          ]
        },
        {
          type: 'row',
          items: [
            {
              name: 'Paino',
              field: 'paino',
              style: { width: '50%' },
              required: false,
              unit: 'kg',
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
            },
            {
              name: 'Pituus',
              field: 'pituus',
              style: { width: '50%' },
              required: false,
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              unit: 'cm'
            },
          ]
        },
        {
          name: 'Kalalaji',
          field: 'kalalaji',
          type: 'select',
          options: ['Ahven',
            'Hauki',
            'Järvitaimen',
            'Järvilohi',
            'Kirjolohi',
            'Lahna',
            'Lohi',
            'Siika',
            'Suutari',
            'Säyne',
            'Muu',
            'Allikkosalakka',
            'Ankerias',
            'Elaska',
            'Harjus',
            'Hietatokko',
            'Hopearuutana',
            'Härkäsimppu (meri)',
            'Härkäsimppu (sisävedet)',
            'Idänsieraintokko',
            'Imukala',
            'Isosimppu',
            'Isotuulenkala',
            'Jokirapu',
            'Kampela',
            'Kapeasaksirapu',
            'Karppi',
            'Kiiski',
            'Kilohaili',
            'Kirjoeväsimppu',
            'Kivennuoliainen',
            'Kivinilkka',
            'Kivisimppu',
            'Kolmipiikki',
            'Kuha',
            'Kuore',
            'Kymmenpiikki',
            'Liejutaskurapu',
            'Liejutokko',
            'Made',
            'Makrilli',
            'Meribassi',
            'Miekkasärki',
            'Muikku',
            'Mustatokko',
            'Mustatäplätokko',
            'Mutu',
            'Nahkiainen',
            'Nieriä',
            'Nokkakala',
            'Pasuri',
            'Piikkikampela',
            'Piikkimonni',
            'Piikkisimppu',
            'Pikkunahkiainen',
            'Pikkutuulenkala',
            'Pilkkusilli',
            'Punakampela',
            'Puronieriä',
            'Rantanuoliainen',
            'Rantataskurapu',
            'Rasvakala',
            'Ruutana',
            'Salakka',
            'Sarda',
            'Sardelli',
            'Seitsenruototokko',
            'Silakka',
            'Silokampela',
            'Siloneula',
            'Sinisampi',
            'Sorva',
            'Sulkava',
            'Särki',
            'Särmäneula',
            'Taimen',
            'Teisti',
            'Toutain',
            'Turpa',
            'Turska',
            'Tähtisampi',
            'Täplärapu',
            'Täpläsilli',
            'Törö',
            'Valkoevätörö',
            'Vaskikala',
            'Venäjänsampi',
            'Viisipiikki',
            'Villasaksirapu',
            'Vimpa'
          ],
          required: true,
        },
        {
          name: 'Väline tai kalastustapa',
          type: 'select',
          field: 'pyydystapa',
          required: true,
          options: ['Ei ilmoitettu',
            'Viehekalastus',
            'Jerkki',
            'Jigi',
            'Lippa',
            'Lusikka',
            'Onki',
            'Perho',
            'Pilkki',
            'Täkyraksi',
            'Vaappu',
            'Muut aktiivivälineet',
            'Atrain',
            'Haavi/lippo',
            'Harppuuna',
            'Painouistin',
            'Pyydyskalastus',
            'Iskukoukku (sis. haukikoukut jne.)',
            'Katiska',
            'Koukku (sis. madekoukut jne.)',
            'Lohiloukku',
            'Merta',
            'Nuotta',
            'Pitkäsiima',
            'Rysä',
            'Verkko',
            'Madehara',
            'Joku muu',]
        },
        {
          name: 'Merkki ja malli',
          field: 'pyydysmalli',
          required: false,
        },
        {
          name: 'Vapautettu',
          type: 'radio-block',
          field: 'c_r',
          options: [{ name: 'Kyllä', value: '1' }, { name: 'Ei', value: '0' }],
          required: true,
        },

        {
          name: 'Lisätietoa säästä',
          field: 'saaolot',
          required: false,
        },
        {
          name: 'Kalatarina',
          field: 'kalatarina',
          type: 'textarea',
        },
      ]
    },
  ],
  [ // page 3
    {
      topHeader: 'Pyyntipaikka',
      items: [
        {
          name: 'Sijainti kartalla',
          type: 'map',
          field: 'sijainti-lat',
          fieldLat: 'sijainti-lat',
          fieldLon: 'sijainti-lng'
        },
        {
          name: 'Lisätietoa paikasta',
          type: 'textarea',
          field: 'pyyntipaikan_lisatiedot'
        }
      ]
    }
  ],
  [ // page 4
    {
      topHeader: 'Kuvat',
      items: [
        {
          name: 'Saaliskuvat',
          type: 'images',
          field: 'kuvat',
          required: false,
          fieldNames: ['kuva', 'kuva_2'],
          limit: 2
        },
        {
          name: 'Viehekuva',
          type: 'images',
          field: 'viehekuva',
          required: false,
          limit: 1
        }
      ]
    }
  ]
]
