import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import CrossLinks from '@otavamedia/om-component-library/lib/CrossLinks'
import { Link } from '../general/util/Links'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import track from 'react-tracking'

const CrossLinkElem = styled(CrossLinks)`
margin-bottom: 2.5rem;

.meta {
  border: none;
  padding-bottom: 0;
  font-family: "Noto Sans";
}
.cross-links-header {
  background-color: rgba(62, 51, 51, 0.8) !important;
  font-family: "IdealSans-SemiBold";
}
.cross-link-divider {
  display: none;
}
.cross-link {
  padding: 0.75rem !important;
}
`

export default @track({ gtmContext: ['CrossLinkBox'] })
class CrossLinkBox extends Component {
  async getCrossLinks () {
    try {
      const links = await WP.getCrossLinks()
      this.props.doneLoading()
      return links
    } catch (e) {
      this.props.doneLoading()
      return []
    }
  }

  render () {
    return <CrossLinkElem getCrossLinks={() => this.getCrossLinks()} LinkComponent={Link}/>
  }
}

CrossLinkBox.propTypes = {
  doneLoading: PropTypes.func.isRequired
}
