import React, { Component } from 'react'

import './WhyOrder.scss'
import eye from '../../assets/ui/eye.svg'
import book from '../../assets/ui/book.svg'
import compass from '../../assets/ui/compass.svg'
import magnify from '../../assets/ui/magnify.svg'
import { Link } from '../general/util/Links'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import PropTypes from 'prop-types'

export default @connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
/**
 * Displays a "Why order MK" box.
 */
class WhyOrder extends Component {
  static propTypes = {
    premiumUser: PropTypes.bool
  }

  render () {
    if (this.props.premiumUser) {
      return null
    }
    const blocks = [
      {
        text: 'Näköislehdet vuodesta 2003',
        img: eye,
        hover: 'Lue kaikki lehdet 20 vuoden ajalta',
        url: '/arkisto/'
      },
      {
        text: 'Kattavia testejä ja vertailuja',
        img: magnify,
        hover: 'Tutkittua tietoa hankintojen perustaksi',
        url: '/kategoria/testit/'
      },
      {
        text: 'Mukana ajassa ja perinteissä',
        img: compass,
        hover: 'Uutuudet, ilmiöt ja erätaidot',
        url: '/uusimmat/'
      },
      {
        text: '12 digilehteä vuodessa',
        img: book,
        hover: 'Jokainen lehti digitaalisessa muodossa ja lisäartikkeleita',
        url: '/digilehdet/'
      },
    ]
    return <div styleName="why-order">
      <h3>Miksi tilata Erä?</h3>
      <p>Erä ennakoi tulevat jahti- ja kalastuskaudet. Tekijöinä alan asiantuntijat.</p>
      <div styleName="blocks">
        {blocks.map((block, key) => <div key={key} styleName="hover-over">
          <div>
            <Link to={{ link: block.url }}>
              <img src={block.img} alt=""/>
              <p>{block.text}</p>
            </Link>
          </div>
          <div styleName="hover">{block.hover}
            <div styleName="caret"></div>
          </div>
        </div>
        )}
      </div>
      <Link to={{ link: 'https://tilaus.eralehti.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_ER&promoCreative=onlinetarjous&promoPosition=syyt-tilata' }}>Tilaa Erä-lehti</Link>
    </div>
  }
}
