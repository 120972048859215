import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './CatchCard.scss'
import DateField from '../widgets/DateField'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import get from 'lodash/get'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { Link } from '../util/Links'
import ShareButtons from '../widgets/ShareButtons'
import ImageModel, { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import Image from '../util/Image'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom'
import { formatNumString } from '@otavamedia/om-component-library/lib/lib/utils'
import defaultFish from '../../../assets/pictures/default-fish.png'

const ConnectedComponent = @track({ gtmContext: ['CatchCard'] }) @connect({
  props: [
    auth, [
      'userData',
    ],
  ],
  actions: [
    auth, [
      'setCommenting',
      'updateModal'
    ]
  ]
})
  /**
   * This displays a single catch card (saaliskortti). It can be displayed in different formats with the 'compact',
   * 'full' and 'highlight' parameters. Can also be displayed without any of those parameters.
   *
   * Full: displays a full page card.
   * Compact: displays a tiny card used in listings.
   * (no parameter): displays a medium size card used in listings
   * Highlight: displays a slightly larger size used in listings (to highlight the first item)
   *
   */
  class CatchCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    setComment: PropTypes.func,
    setCommenting: PropTypes.func,
    isLast: PropTypes.bool,
    isReply: PropTypes.bool,
    userData: PropTypes.object,
    admins: PropTypes.array,
    showStars: PropTypes.bool,
    location: PropTypes.object,
    showTitle: PropTypes.bool,
    showImage: PropTypes.bool,
    hideActions: PropTypes.bool,
    text: PropTypes.string,
    compact: PropTypes.bool,
    full: PropTypes.bool,
    withForm: PropTypes.bool,
    highlight: PropTypes.bool,
    type: PropTypes.string,
    imagePreviews: PropTypes.array,
  }

  state = {}

  constructor (props) {
    super(props)
    this.state.thumbs = get(props, 'data.acf.peukut', [])
  }

  UNSAFE_componentWillReceiveProps (props) {
    const thumbs = get(props, 'data.acf.peukut', [])
    this.setState({ thumbs })
  }

  async toggleLike () {
    if (this.props.userData) {
      const like = !!this.props.userData && !!this.state.thumbs && this.state.thumbs.includes(this.props.userData.id)
      let thumbs = this.state.thumbs || []
      if (!like) {
        thumbs.push(this.props.userData.id)
      } else {
        thumbs = thumbs.filter(x => (x !== this.props.userData.id))
      }
      this.setState({ thumbs })
      await WP.likeComment(this.props.data, !like)
    }
  }

  formatTime (value) {
    return value ? value.replace(/(\d+):(\d+):(\d+)/, '$1.$2') : ''
  }

  formatItem (value, item) {
    if (item.replace && (typeof value === 'string' || value instanceof String)) {
      return value.replace(item.match, item.replace)
    }
    return value
  }

  renderField (item, formAnswers, key) {
    if (item.type !== 'row' && (formAnswers[item.field] === '' || formAnswers[item.field] === null || formAnswers[item.field] === undefined || item.noSummary)) {
      return null
    }

    switch (item.type) {
    case 'row':
      return item.items.map((subItem, idx3) => {
        const rowData = this.renderField(subItem, formAnswers)
        return rowData ? <div key={key + ' ' + idx3} styleName="summary-item">{rowData}</div> : null
      })
    case 'radio-block':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{formAnswers[item.field]}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{item.options[formAnswers[item.field] ? 1 : 0]}
          </Fragment>}
      </div>
    case 'date':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label"><DateField date={formAnswers[item.field]} dateOnly/></b>
          : <Fragment><b styleName="label">{item.name}: </b><DateField
            date={formAnswers[item.field]}
            dateOnly/></Fragment>}
      </div>
    case 'time':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{this.formatTime(formAnswers[item.field])}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{this.formatTime(formAnswers[item.field])}
          </Fragment>}
      </div>
    default:
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{formAnswers[item.field]}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{this.formatItem(formAnswers[item.field], item)} {item.unit}
          </Fragment>}
      </div>
    }
  }

  renderSummary (type) {
    const { data } = this.props
    const formAnswers = data.acf

    return textFields[type].map((formPage, page) =>
      <div key={'x' + page} styleName="column">
        {formPage.map((section, key) => {
          const itemData = section.items.map((item, key2) => this.renderField(item, formAnswers, key2)).filter(x => x)
          return itemData.length
            ? <div key={'x' + page + 'x' + key} styleName="">
              {section.topHeader && <h3 styleName="subtitle">{section.topHeader}</h3>}
              {section.title && <h3>{section.title}</h3>}
              {itemData}
            </div>
            : null
        }
        )}
      </div>
    )
  }

  renderImagePreview () {
    const imagePreviews = Object.values(this.props.imagePreviews).flatMap(curr => curr)
    if (!imagePreviews[0]) {
      imagePreviews[0] = defaultFish
    }

    return <Fragment>
      <div styleName="image preview" style={{ backgroundImage: 'url(' + imagePreviews[0] + ')', border: 'none' }}>
      </div>
      <div styleName="more-images">
        {imagePreviews && imagePreviews.map((imagePreview, key) => !!key && <div styleName="additional-image preview" key={key}
          style={{ backgroundImage: 'url(' + imagePreview + ')', border: 'none' }}>
        </div>)}
      </div>
    </Fragment>
  }

  render () {
    const { data, isLast, userData, compact, full, withForm, type, highlight, imagePreviews } = this.props
    const { thumbs } = this.state

    if (!data) {
      return null
    }

    const url = '/saalislista/'
    const title = 'Saalislista'

    const like = !!userData && !!thumbs && thumbs.includes(userData.id)
    const thumbCount = thumbs.length
    const kuvat = []
    if (data.acf.kuva) {
      kuvat.push(new ImageModel(data.acf.kuva))
    }
    if (data.acf.kuva_2) {
      kuvat.push(new ImageModel(data.acf.kuva_2))
    }
    if (data.acf.viehekuva) {
      kuvat.push(new ImageModel(data.acf.viehekuva))
    }
    const kuva = kuvat && kuvat[0]

    if (compact) {
      return <div styleName="main compact" id={isLast ? 'lastCard' : ''}>
        <Link to={{ link: url + data.id }}>
          <div styleName="image">
            {kuva
              ? <Image data={kuva} size={IMAGE_SIZE.THUMBNAIL} sizes={'225px'}/>
              : <img src={defaultFish} alt="" role="presentation"/>
            }
          </div>
        </Link>
        <div styleName="header">
          <div styleName="meta">
            <Link to={{ link: url + data.id }}>
              <ul>
                <li>
                  <div>{data.acf.pyydystaja}</div>
                </li>
                <li>
                  <div>{data.acf.kalalaji}</div>
                </li>
                <li>
                  <div>{data.acf.paino ? formatNumString(data.acf.paino, 1) + ' kg' : '- kg'}</div>
                  <div>{data.acf.pituus ? formatNumString(data.acf.pituus) + ' cm' : '- cm'}</div>
                </li>
              </ul>
            </Link>
          </div>
        </div>
      </div>
    } else if (full) {
      return <div styleName={withForm ? 'full with-form' : 'full'}>
        <div styleName="top-meta">{title}</div>
        <h1>{data.acf.pyydystaja}</h1>
        <div styleName="meta">
          <ul>
            <li>
              <div>{data.acf.paino ? formatNumString(data.acf.paino, 1) + ' kg' : '- kg'}</div>
            </li>
            <li>
              <div>{data.acf.pituus ? formatNumString(data.acf.pituus) + ' cm' : '- cm'}</div>
            </li>
            <li>
              <span><DateField date={data.acf.pyyntipvm} dateOnly/></span>
            </li>
            <li>
            </li>
          </ul>
        </div>
        {imagePreviews
          ? this.renderImagePreview()
          : <Fragment>
            <div styleName="image">
              {kuva
                ? <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'} addToGallery/>
                : <img src={defaultFish} alt="" role="presentation"/>
              }
            </div>
            <div styleName="more-images">
              {kuvat && kuvat.map((img, key) => !!key && <div styleName="additional-image" key={key}>
                <Image data={img} size={IMAGE_SIZE.THUMBNAIL} sizes={'225px'} addToGallery/>
              </div>)}
            </div>
          </Fragment>}
        {withForm
          ? null
          : <div styleName="footer">
            <div styleName={'like-button ' + (like ? 'i-like' : '')}>
              <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
              {thumbCount > 0 ? thumbCount : null}
            </div>
            <div>
              <ShareButtons article={{ link: window.location.pathname }} shareCount={0}/>
            </div>
          </div>}
        <div styleName="texts">
          {this.renderSummary(type)}
        </div>
      </div>
    } else if (highlight) {
      return <div styleName={'main highlight'} id={isLast ? 'lastCard' : ''}>
        <div styleName="image">
          <Link to={{ link: url + data.id }}>
            {kuva
              ? <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'} addToGallery/>
              : <img src={defaultFish} alt="" role="presentation"/>
            }
          </Link>
        </div>
        <div styleName="header">
          <Link to={{ link: url + data.id }}>
            <div styleName="name">
              {data.acf.pyydystaja}
            </div>
          </Link>
          <div styleName="type-of-fish">{data.acf.kalalaji}</div>
          <div>
            <div styleName="meta">
              <ul>
                <li>
                  <div>{data.acf.paino ? formatNumString(data.acf.paino, 1) + ' kg' : '- kg'}</div>
                </li>
                <li>
                  <div>{data.acf.pituus ? formatNumString(data.acf.pituus) + ' cm' : '- cm'}</div>
                </li>
                <li>
                  <span><DateField date={data.acf.pyyntipvm} dateOnly/></span>
                </li>
                <li>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div styleName="footer">
          <div styleName={'like-button ' + (like ? 'i-like' : '')}>
            <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
            {thumbCount > 0 ? thumbCount : null}
          </div>
        </div>
      </div>
    }
    return <div styleName={'main'} id={isLast ? 'lastCard' : ''}>
      <div styleName="image">
        <Link to={{ link: url + data.id }}>
          {kuva
            ? <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'} addToGallery/>
            : <img src={defaultFish} alt="" role="presentation"/>
          }
        </Link>
      </div>
      <div styleName="header">
        <Link to={{ link: url + data.id }}>
          <div styleName="name">
            {data.acf.pyydystaja}
          </div>
          <div styleName="type-of-fish">{data.acf.kalalaji}</div>
          <div styleName="meta">
            <ul>
              <li>
                <div>{data.acf.paino ? formatNumString(data.acf.paino, 1) + ' kg' : '- kg'}</div>
              </li>
              <li>
                <div>{data.acf.pituus ? formatNumString(data.acf.pituus) + ' cm' : '- cm'}</div>
              </li>
              <li>
                <span><DateField date={data.acf.pyyntipvm} dateOnly/></span>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </Link>
      </div>
      <div styleName="footer">
        <div styleName={'like-button ' + (like ? 'i-like' : '')}>
          <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
          {thumbCount > 0 ? thumbCount : null}
        </div>
      </div>
    </div>
  }
}

export default withRouter(ConnectedComponent)

const textFields = {
  fish:
    [
      [ // page 2
        {
          title: 'Saaliin tiedot',
          items: [
            {
              name: 'Kalalaji',
              field: 'kalalaji',
            },
            {
              name: 'Paino',
              field: 'paino',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'kg'
            },
            {
              name: 'Pituus',
              field: 'pituus',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'cm'
            },
            {
              name: 'Pyydysmalli',
              field: 'pyydysmalli',
            },
            {
              name: 'Väline tai kalastustapa',
              field: 'pyydystapa',
            },
            {
              name: 'Kalatarina',
              field: 'kalatarina',
            },
            {
              name: 'Vapautettu',
              type: 'radio-block',
              options: { 0: 'Ei', 1: 'Kyllä' },
              field: 'c_r'
            },
            {
              name: 'Saantipäivä',
              field: 'pyyntipvm',
              type: 'date',
              style: { width: '50%' },
            },
            {
              name: 'Kellonaika',
              field: 'pyyntiaika',
              type: 'time',
              style: { width: '50%' },
            },
            {
              name: 'Lisätietoa säästä',
              field: 'saaolot',
            },
            {
              name: 'Lisätietoa paikasta',
              type: 'textarea',
              field: 'pyyntipaikan_lisatiedot'
            },
          ]
        },
      ],
    ],

}
