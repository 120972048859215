import React, { Component, Fragment } from 'react'
import './ClubForm.scss'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import { withRouter } from 'react-router-dom'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import Map from './Map'
import CatchCard from '../general/comments/CatchCard'
import application from '@otavamedia/om-component-library/lib/kea/application'
import { HTML } from '@otavamedia/om-component-library/lib/util'

@connect({
  actions: [
    auth, [
      'updateModal',
    ],
    application, [
      'setRendered'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
      'userData',
    ]
  ]
})
  /**
   * This displays a custom multipage form fort submitting user generated content as comments as with acf fields.
   * The form information is provided in json format to this component ('formPages' and 'texts' parameters).
   * Features:
   * - form validation
   * - field types: select dropdown, checkbox, radio-block (radio buttons that look like buttons),
   *   radio-group (normal radio buttons), text field, regexp pattern, image file, mutiselect,
   *   textarea, map location.
   * - required fields
   * - fields shown based on value in another field
   * - post processing of input values with string replace
   */
class ClubForm extends Component {
  static propTypes = {
    type: PropTypes.string,
    formPages: PropTypes.array,
    actions: PropTypes.object,
    texts: PropTypes.object,
    loggedIn: PropTypes.bool,
    userData: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      started: false,
      finished: false,
      page: 0,
      formAnswers: {},
      imagePreviews: [],
      selectedFiles: [],
      fileNames: [],
      fileError: [],
      errors: {},
      missing: {},
      okToSend: true,
      view: null
    }
    if (this.props.userData) {
      this.props.formPages.forEach(page => page[0].items.forEach(item => {
        if (item.default) {
          this.state.formAnswers[item.field] = this.props.userData[item.default]
        }
      }))
    }
    this.loadPage()
  }

  async loadPage () {
    const page = await WP.getForURL(WP.url + window.location.pathname + '-teksti')
    this.setState({ view: page.data })
  }

  findItem (fieldName) {
    let foundItem
    this.props.formPages.forEach(page => page[0].items.forEach(item => {
      if (item.field === fieldName) {
        foundItem = item
        return
      }
      if (item.items) {
        item.items.forEach(subItem => {
          if (subItem.field === fieldName) {
            foundItem = item
          }
        })
      }
    }))
    return foundItem
  }

  async submitComment () {
    this.setState({ okToSend: false, message: '', error: false })
    const imageIds = await WP.submitImagesToClub(Object.values(this.state.selectedFiles).flatMap(curr => curr), '/era-kayttajat')
    let idx = 0
    Object.keys(this.state.selectedFiles).forEach(field => {
      const item = this.findItem(field)
      if (item.limit === 1) {
        this.state.formAnswers[field] = imageIds[idx]
      } else if (item.fieldNames && item.fieldNames.length) {
        let nameIdx = 0
        this.state.selectedFiles[field].forEach(file => {
          this.state.formAnswers[item.fieldNames[nameIdx]] = imageIds[idx]
          idx++
          nameIdx++
        })
      } else {
        this.state.formAnswers[field] = []
        this.state.selectedFiles[field].forEach(file => {
          this.state.formAnswers[field].push(imageIds[idx])
          idx++
        })
      }
    })
    const response = await WP.submitToClub({
      ...this.state.formAnswers,
      content: Object.keys(this.state.formAnswers)
        .reduce((acc, currentItem) => (acc || '') + currentItem + ': ' + this.state.formAnswers[currentItem] + '\n', ''),
      post: window.location.host.includes('asteaws') ? window.om_constants.catchListIdDev : window.om_constants.catchListId
    }, null, '/era-kayttajat')

    window.scrollTo(0, 0)
    if (response.error) {
      this.setState({
        message: `Ilmoittautumisen lähettäminen epäonnistui: ${response.error}`,
        error: true,
        okToSend: true,
      })
    } else if (response.status === 'hold') {
      this.setState({
        message: 'Saalisilmoitus julkaistaan sivuilla sen jälkeen, kun toimitus on hyväksynyt sen.',
        error: false,
        finished: true
      })
    } else if (response.status === 'approved') {
      this.setState({
        message: 'Ilmoittautuminen lähetetty, kiitos!',
        error: false,
        finished: true
      })
    } else if (response.status === 'spam' || response.status === 'trash') {
      this.setState({
        message: 'Ilmoittautumista ei hyväksytä',
        error: true,
      })
    } else if (response.data && response.data.status === 409) {
      this.setState({
        message: 'Olet jo ilmoittanut tämän saaliin',
        error: true,
      })
    } else if (response.message) {
      this.setState({
        message: response.message,
        error: true,
      })
    }
  }

  goToPage (page, started = true) {
    this.setState({ page, started },
      () => {
        document.querySelector('#form-header') && document.querySelector('#form-header').scrollIntoView(false)
      })
  }

  handleInput = (event, item) => {
    const inputValue = event.currentTarget.type === 'checkbox'
      ? event.currentTarget.checked
      : (item.mapValues ? item.mapValues[event.currentTarget.value] : event.currentTarget.value)
    const formAnswers = this.state.formAnswers
    if (item.match) {
      formAnswers[item.field] = inputValue.replace(item.match, item.replace)
    } else {
      formAnswers[item.field] = inputValue
    }
    this.setState({
      formAnswers,
    })
  }

  /*
   * Validates the current page, returns false if any errors or missing values are found
   */
  validatePage = () => {
    const { page, formAnswers } = this.state
    const { formPages } = this.props

    return !formPages[page].reduce((previousMissing, section) => {
      return section.items.reduce((previousMissing, item) => {
        if (item.type === 'row') {
          return item.items.reduce((previousMissing, subItem) => {
            return this.validateElement({ currentTarget: { value: formAnswers[subItem.field] } }, subItem) || previousMissing
          }, false) || previousMissing
        } else {
          return this.validateElement({ currentTarget: { value: formAnswers[item.field] } }, item) || previousMissing
        }
      }, false) || previousMissing
    }, false)
  }

  validateElement = (event, item) => {
    const inputValue = event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value
    const missing = this.state.missing
    const errors = this.state.errors
    if (item.type === 'multiselect') {
      // if field is required and it is shown on the screen, check that at least one option is selected
      if (item.required && (!item.showIfField || this.state.formAnswers[item.showIfField] === item.showIf)) {
        missing[item.field] = !item.items.some((subItem) => this.state.formAnswers[subItem.field])
        this.setState({ missing })
        return missing[item.field]
      } else {
        return false
      }
    }

    if (item.type === 'images') {
      // if image field is required, check that at least one image is selected
      if (item.required && (!this.state.selectedFiles[item.field] || !this.state.selectedFiles[item.field].length)) {
        missing[item.field] = true
        this.setState({ missing })
        return missing[item.field]
      } else {
        return false
      }
    }
    // if field is required and visible, check that it has a value
    missing[item.field] = !!((item.required && (!item.showIfField || this.state.formAnswers[item.showIfField] === item.showIf)) && !inputValue)
    // if field has a value, check that it matches the given pattern (if there is one specified)
    errors[item.field] = !missing[item.field] && inputValue && !!(item.pattern && !inputValue.match(item.pattern))
    this.setState({ errors, missing })

    return missing[item.field] || errors[item.field]
  }

  updateState (fieldName, newPosition) {
    const formAnswers = this.state.formAnswers
    formAnswers[fieldName] = newPosition
    this.setState({
      formAnswers,
    })
  }

  renderSummary () {
    const { formAnswers } = this.state
    const { formPages } = this.props
    const section = formPages[0][0]

    return <div>
      {section.title && <h3 styleName="main-title">{section.title}</h3>}
      <div styleName="section">
        {section.items.map((item, key2) => {
          if (item.noSummary) {
            return null
          }
          return <div key={key2} styleName="summary-item">
            {item.type === 'checkbox'
              ? <Fragment>
                {item.noLabel
                  ? <b styleName="label">{formAnswers[item.field] ? item.name : null}</b>
                  : <Fragment><b styleName="label">{item.name}: </b>{formAnswers[item.field] ? 'Kyllä' : 'Ei'}
                  </Fragment>}
                {!!formAnswers[item.field] && item.items
                  ? <Fragment><br/>{item.items.map((subItem, idx3) =>
                    <Fragment key={idx3}>
                      {subItem.noLabel
                        ? <b styleName="label">{formAnswers[subItem.field]}</b>
                        : <Fragment><b
                          styleName="label">{subItem.name}: </b>{formAnswers[subItem.field]} {subItem.unit}<br/></Fragment>}
                    </Fragment>)}</Fragment>
                  : null}
              </Fragment>
              : null}
            {item.type === 'multiselect'
              ? item.items.map((subItem, idx3) =>
                <Fragment key={idx3}>
                  {subItem.noLabel
                    ? <Fragment><b
                      styleName="label">{formAnswers[subItem.field] ? subItem.name : null}</b><br/></Fragment>
                    : <Fragment><b
                      styleName="label">{subItem.name}: </b>{formAnswers[subItem.field] ? 'Kyllä' : 'Ei'}<br/></Fragment>}
                </Fragment>)
              : null}
            {item.type === 'textarea'
              ? <Fragment>
                {item.noLabel
                  ? <b styleName="label">{formAnswers[item.field]}</b>
                  : <Fragment><b styleName="label">{item.name}: </b>{formAnswers[item.field]}</Fragment>}
              </Fragment>
              : null}
            {item.type === 'select'
              ? <Fragment>
                {item.noLabel
                  ? <b styleName="label">{formAnswers[item.field]}</b>
                  : <Fragment><b styleName="label">{item.name}: </b>{formAnswers[item.field]}</Fragment>}
              </Fragment>
              : null}
            {item.type === 'row'
              ? item.items.map((subItem, idx3) =>
                <Fragment key={idx3}>
                  {subItem.noLabel
                    ? <Fragment><b styleName="label">{formAnswers[subItem.field]}</b>&nbsp;</Fragment>
                    : <Fragment><b styleName="label">{subItem.name}: </b>{formAnswers[subItem.field]} {subItem.unit}<br/></Fragment>}
                </Fragment>)
              : null}
            {item.type === 'images' ? this.renderImageSummary(item) : null}
            {!item.type || item.type === 'map'
              ? <Fragment>
                {item.noLabel
                  ? <b styleName="label">{formAnswers[item.field]}</b>
                  : <Fragment><b styleName="label">{item.name}: </b>{formAnswers[item.field]} {item.unit}</Fragment>}
              </Fragment>
              : null}
          </div>
        })}
      </div>
      <div styleName="summary-card">
        <CatchCard data={{ acf: formAnswers }} full withForm type={this.props.type}
          imagePreviews={this.state.imagePreviews}/>
      </div>
    </div>
  }

  renderImageSummary (item) {
    const imagePreviews = this.state.imagePreviews[item.field]
    return <div styleName="image-list">
      <b>{item.name}</b><br/>
      <div styleName="image-list">
        {imagePreviews.map((imagePreview, idx) =>
          <div styleName="file-input" key={idx}
            style={{ backgroundImage: 'url(' + imagePreview + ')', border: 'none' }}>
          </div>
        )}
      </div>
    </div>
  }

  componentDidMount () {
    this.props.actions.setRendered(true)
  }

  openForm () {
    this.goToPage(0)
  }

  fileChanged (e, field) {
    let fileName = ''
    if (e.target.files && e.target.files.length > 1) {
      fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', e.target.files.length)
    } else {
      fileName = e.target.value.split('\\').pop()
    }
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

    if (!allowedExtensions.exec(fileName)) {
      const file = document.getElementById('file')
      file.value = file.defaultValue
    } else if (e.target.files[0].size > 10 * 1024 * 1024) {
      const file = document.getElementById('file')
      file.value = file.defaultValue
      const fileError = this.state.fileError[field]
      fileError[field] = 'Tiedosto on liian iso, lähetä korkeintaan 10 MB kuvatiedosto'
      this.setState({ fileError })
    } else {
      const selectedFiles = this.state.selectedFiles
      const imagePreviews = this.state.imagePreviews
      const fileNames = this.state.fileNames
      if (!selectedFiles[field]) selectedFiles[field] = []
      if (!imagePreviews[field]) imagePreviews[field] = []
      if (!fileNames[field]) fileNames[field] = []
      selectedFiles[field].push(e.target.files[0])
      imagePreviews[field].push(URL.createObjectURL(e.target.files[0]))
      fileNames[field].push(fileName)

      const fileError = this.state.fileError
      fileError[field] = null

      this.setState({ selectedFiles, fileNames, imagePreviews, fileError })
    }
  }

  removeImage (field, idx) {
    const selectedFiles = this.state.selectedFiles
    const imagePreviews = this.state.imagePreviews
    const fileNames = this.state.fileNames
    selectedFiles[field].splice(idx, 1)
    imagePreviews[field].splice(idx, 1)
    fileNames[field].splice(idx, 1)
    this.setState({ selectedFiles, imagePreviews, fileNames })
  }

  renderImageInput (item) {
    const imagePreviews = this.state.imagePreviews[item.field] || []
    const fileError = this.state.fileError[item.field]
    return <Fragment>
      <div>{item.name}</div>
      <div styleName="image-list">
        {imagePreviews.map((imagePreview, idx) =>
          <div styleName="file-input" key={idx}
            style={{ backgroundImage: 'url(' + imagePreview + ')', border: 'none' }}>
            <input type="file" id={'file' + item.field} styleName="inputfile"
              accept="image/jpeg,.jpg,.jpeg,.jpe,image/png,.png,image/gif,.gif"
              onChange={(e) => this.fileChanged(e, item.field)}/>
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor={'file' + item.field} styleName="remove-image" onClick={(e) => {
              this.removeImage(item.field, idx)
              e.preventDefault()
            }}>Poista</label>
          </div>
        )}
        {imagePreviews.length < item.limit
          ? <div styleName="file-input" key={imagePreviews.length}>
            <input type="file" id={'file' + item.field} styleName="inputfile"
              accept="image/jpeg,.jpg,.jpeg,.jpe,image/png,.png,image/gif,.gif"
              onChange={(e) => this.fileChanged(e, item.field)}/>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor={'file' + item.field} styleName="add-image"><span className="fa">{'\uf067'}</span></label>
          </div> : null
        }
      </div>
      <Fragment>
        {item.required && (!imagePreviews || !imagePreviews.length) ? <span styleName="error-msg"> Kuva vaaditaan</span> : null}
      </Fragment>
      {fileError && <div styleName="error-msg">{fileError}</div>}
    </Fragment>
  }

  renderRequired (item, errors, missing) {
    return <Fragment>
      {item.required ? <span styleName="required">*</span> : null}
      {errors[item.field] ? <span styleName="error-msg"> Arvo ei kelpaa</span> : null}
      {missing[item.field] ? <span styleName="error-msg"> Tieto vaaditaan</span> : null}
    </Fragment>
  }

  renderItem (item, formAnswers, key2) {
    const { errors, missing } = this.state

    if (item.showIfField && JSON.stringify(formAnswers[item.showIfField]) !== JSON.stringify(item.showIf)) {
      return null
    }

    /* eslint-disable jsx-a11y/label-has-for */
    return <Fragment key={key2}>{item.type === 'checkbox'
      ? <Fragment>
        <div key={item.field} styleName="item" style={item.style ? item.style : null}>
          <div styleName="checkbox-box">
            <label htmlFor={item.field} styleName="checkbox"><input id={item.field} type="checkbox"
              checked={!!formAnswers[item.field]} onChange={(e) => this.handleInput(e, item)}/>
            <HTML>{item.name}</HTML>{this.renderRequired(item, errors, missing)}
            </label>
          </div>
        </div>
        {!!formAnswers[item.field] && item.items
          ? <div key={key2} styleName="item with-sub-item">{item.items.map((subItem, idx3) => {
            return this.renderItem(subItem, formAnswers, idx3)
          })}</div>
          : null}
      </Fragment>
      : null}
    {item.type === 'images' ? this.renderImageInput(item) : null}
    {item.type === 'textarea'
      ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={item.field}>{item.name}{this.renderRequired(item, errors, missing)}</label>
        <textarea id={item.field} onBlur={(e) => this.validateElement(e, item)} onChange={(e) => this.handleInput(e, item)} value={formAnswers[item.field]}></textarea>
      </div>
      : null}
    {item.type === 'select' ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={item.field}>{item.name}{this.renderRequired(item, errors, missing)}</label>
      <select id={item.field} required={!!item.required} onBlur={(e) => {
        this.validateElement(e, item)
        this.handleInput(e, item)
      }} defaultValue={formAnswers[item.field]}>
        <option></option>
        {item.options.map((option, key) => <option key={key}>{option}</option>)}
      </select>
    </div> : null}
    {item.type === 'row'
      ? <div key={key2} styleName="item with-sub-item">{item.items.map((subItem, idx3) => {
        return this.renderItem(subItem, formAnswers, idx3)
      })}</div>
      : null}
    {item.type === 'map'
      ? <div key={key2} styleName="item">
        <Map lat={this.state.lat} lng={this.state.lng} zoom={this.state.zoom}
          updateState={(x) => {
            if (item.fieldLat) {
              this.updateState(item.fieldLat, x.replace(/[0-9.]+.E ([0-9.]+).N/, '$1'))
              this.updateState(item.fieldLon, x.replace(/([0-9.]+).E [0-9.]+.N/, '$1'))
            } else {
              this.updateState(item.field, x)
            }
          }}/>
      </div>
      : null}
    {item.type === 'multiselect'
      ? <Fragment>
        <div key={key2} styleName="item">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>{item.name}{this.renderRequired(item, errors, missing)}</label>
        </div>
        {item.items.map((subItem, idx3) => {
          return this.renderItem(subItem, formAnswers, idx3)
        })}
      </Fragment>
      : null}
    {item.type === 'radio-block'
      ? <Fragment>
        <div key={key2} styleName="item">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>{item.name}{this.renderRequired(item, errors, missing)}</label>
        </div>
        <div styleName="radio-block">
          {item.options.map((option, key) => <div key={key}>
            <input type="radio" name={item.name} id={option.name} onBlur={(e) => this.validateElement(e, item)}
              styleName="radio" value={option.value} checked={formAnswers[item.field] === option.value || (item.mapValues && formAnswers[item.field] === item.mapValues[option.value])}
              onChange={(e) => this.handleInput(e, item)}/>
            <label htmlFor={option.name}>{option.name}</label>
          </div>)}
        </div>
      </Fragment>
      : null}
    {item.type === 'radio-buttons'
      ? <Fragment>
        <div key={key2} styleName="item">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>{item.name}{this.renderRequired(item, errors, missing)}</label>
        </div>
        <div styleName="radio-buttons">
          {item.options.map((option, key) => <label key={key}>
            <input type="radio" name={item.name} onBlur={(e) => this.validateElement(e, item)} styleName="radio" value={option}
              checked={formAnswers[item.field] === option} onChange={(e) => this.handleInput(e, item)}/>{option}
          </label>)}
        </div>
      </Fragment>
      : null}
    {item.type === 'date'
      ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={item.field}>{item.name}{this.renderRequired(item, errors, missing)}</label>
        <input id={item.field} type="date" onBlur={(e) => this.validateElement(e, item)} defaultValue={formAnswers[item.field]}
          onChange={(e) => this.handleInput(e, item)}/>
        <div styleName="unit">{item.unit}</div>
      </div>
      : null}
    {item.type === 'time'
      ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={item.field}>{item.name}{this.renderRequired(item, errors, missing)}
        </label>
        <input id={item.field} type="text" onBlur={(e) => this.validateElement(e, item)}
          defaultValue={formAnswers[item.field]}
          onChange={(e) => this.handleInput(e, item)}/>
        <div styleName="unit">{item.unit}</div>
      </div>
      : null}
      {item.type === 'info'
        ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label><HTML>{item.name}</HTML></label>
        </div>
        : null}
    {!item.type
      ? <div key={key2} styleName="item" style={item.style ? item.style : null}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={item.field}>{item.name}{this.renderRequired(item, errors, missing)}
        </label>
        <input id={item.field} type="text" onBlur={(e) => this.validateElement(e, item)}
          readOnly={!!item.readonly}
          defaultValue={formAnswers[item.field]}
          onChange={(e) => this.handleInput(e, item)}/>
        <div styleName="unit">{item.unit}</div>
      </div>
      : null}
    </Fragment>
  }

  renderFormPage () {
    const { page, formAnswers } = this.state
    const { formPages } = this.props
    if (page === formPages.length) {
      return <div styleName="summary">{this.renderSummary()}</div>
    }
    return formPages[page].map((section, key) =>
      <div key={'x' + page + 'x' + key} styleName="section">
        {section.title && <h3>{section.title} <span styleName="required small">* Pakollinen tieto</span></h3>}

        {section.items.map((item, key2) => {
          return this.renderItem(item, formAnswers, key2)
        })}
      </div>
    )
  }

  renderHeader () {
    const { page } = this.state
    const { formPages } = this.props

    return <div styleName="header" id="form-header">
      <div>{page > 0
        ? <button onClick={() => this.goToPage(page - 1)}>{'\uf060'}</button>
        : null
      }</div>
      <div styleName="page-selector">
        <div>{formPages[page] ? formPages[page][0].topHeader : <Fragment>Vahvista ja lähetä</Fragment>}</div>
        <div>
          {formPages.map((thisPage, idx) => <span key={idx}
            styleName={idx === page ? 'selected' : null}>{'\uf111'}</span>)}
          <span styleName={formPages.length === page ? 'selected' : null}>{'\uf111'}</span>
        </div>
      </div>
      <div>{page < formPages.length ? <button onClick={() => this.validatePage() && this.goToPage(page + 1)}>{'\uf061'}</button> : null}</div>
    </div>
  }

  renderFooter () {
    const { page, started, finished, error, message, okToSend } = this.state
    const { formPages, loggedIn } = this.props

    if (finished) return null

    return <div styleName="footer">
      {error ? <div styleName="error-message">{message}</div> : null}
      {started && <button styleName="cancel-button" onClick={() => this.goToPage(0, false)}>Keskeytä</button>}
      {!started
        ? (loggedIn
          ? <button styleName="next-button" onClick={() => this.openForm()}>Aloita</button>
          : <a styleName="next-button" href={WP.getLoginUrl()}>
            Kirjaudu sisään aloittaaksesi</a>)
        : (page < formPages.length
          ? <button styleName="next-button" onClick={() => this.validatePage() && this.goToPage(page + 1)}>Seuraava</button>
          : <button styleName="next-button" disabled={!okToSend} onClick={() => this.submitComment()}>{okToSend ? 'Lähetä' : 'Lähetetään...'}</button>
        )
      }
    </div>
  }

  render () {
    const { started, finished, message, view } = this.state
    const { intro, outro } = this.props.texts
    if (!started) {
      return <div styleName="form-page">
        <div styleName="small-title" id="form-header">{intro.smallTitle}</div>
        <div styleName="main-title">{view && view.title}</div>
        <div styleName="text"><HTML>{view && view.content}</HTML></div>
        {this.renderFooter()}
      </div>
    }
    if (finished) {
      return <div styleName="form-page">
        <div styleName="main-title" id="form-header">{outro.mainTitle}</div>
        <div styleName="text">{message}</div>
      </div>
    }
    return <div styleName="form-page">
      {this.renderHeader()}
      <div styleName="form">
        {this.renderFormPage()}
      </div>
      {this.renderFooter()}
    </div>
  }
}

export default withRouter(ClubForm)
