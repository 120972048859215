import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'

import './PaywallBanner.scss'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

@connect({
  props: [
    application, [
      'view',
    ],
    auth, [
      'shareTokenAccess',
    ],
  ],
})
class PaywallBanner extends Component {
  static propTypes = {
    showText: PropTypes.bool,
    shareTokenAccess: PropTypes.func,
    view: PropTypes.object
  }

  render () {
    const { showText, shareTokenAccess, view } = this.props

    if (!shareTokenAccess(view)) return null
    return <div styleName={'banner' + (showText ? '' : ' top-only')}>
      <div styleName="top-bar">Tämä on ilmaisnäyte Erän maksullisesta sisällöstä</div>
      {showText
        ? <div styleName="text">
          <p styleName="text-header">Kiitos, että viihdyit Erän artikkelin parissa!</p>
          <p>Tilaamalla Erän pääset lukemaan kaikkea tilaajille tarkoitettua sisältöä,
            kuten koko vuoteen 2003 ulottuvaa <Link to={{ link: '/arkisto/' }}>näköislehtiarkistoamme</Link>,
            viikottain ilmestyvän <Link to={{ link: '/digilehdet/' }}>digilehden</Link>,
             sekä <Link to={{ link: '/testit/' }}>testejämme ja vertailujamme</Link> retkeilijöiden varusteista.</p>
          <p><Link to={{ link: 'https://tilaus.eralehti.fi/tilaus?promoID=OM_linkki&promoName=OM_ER-shared&promoCreative=onlinetarjous&promoPosition=shared' }}>Tutustu tarkemmin uusille tilaajille tarkoitettuihin tarjouksiimme täällä!</Link></p>
        </div>
        : null
      }
    </div>
  }
}

export default PaywallBanner
