import React from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './Title.scss'

const Title = ({ text }) => {
  return (
    <div styleName="title-container">
      <div styleName="title-icon">
      </div>
      <h2 styleName="title-content">
        <HTML>{text}</HTML>
      </h2>
    </div>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  displayLogo: PropTypes.bool,
}

export default Title
