window.om_constants = {
  siteName: 'Erä-lehti',
  siteNameGen: 'Erä-lehden',
  aboutSite: 'Erästä',
  toSite: 'Erä-lehteen',
  magName: 'Erä-lehti DIGI',
  magNameGen: 'Erä-lehti DIGIn',
  archiveHighlights: 'Erä-lehti testaa - Arkistot kertovat',
  archiveHighlightsDesc: 'Selaile Erä-lehden erikoisnumeroita vuosien varrelta.',
  abbreviation: 'Erä',
  paywallHost: 'eralehti.fi',
  paywallBrandId: 'ER',
  offerId: '187451',
  offerIdDev: '8881',
  catchListId: '405988',
  catchListIdDev: '404799',
  clientId: 'c4dac9b4-6e31-47fa-b49f-a580f7a654e2',
  clientIdDev: '2d7ba9cd-2470-4d71-a6be-d68cf8de1079',
  appName: 'openid2',
  newsletterText: 'Tilaa Erän kerran viikossa lähetettävä uutiskirje, niin pysyt ajan tasalla kalastamiseen, retkeilyyn ja luontoon liittyvistä uutisista ja uusimmista jutuistamme!',
  ctaText1: ', vastuullinen luontosuhde perustuu oikeaan tietoon. Mitä enemmän tiedetään ja mitä vähemmän luullaan, sitä lujempi suomalaisesta luontosuhteesta muodostuu. ' +
    'Erä-lehti haluaa rakentaa ja puolustaa vastuullista luontosuhdetta tuottamalla ja jakamalla tietoa, jotta huhuille ja luuloille ei jäisi tilaa. ' +
    'Siinä tarvitsemme myös Sinun panostasi.',
  ctaText2: ', luulojen pahin vihollinen on tieto. Juuri nyt tiedon tarve on suurimmillaan ja luuloista on tavallista enemmän haittaa. Erä-lehti keskittyy tietoon, jotta luuloille ei jäisi mahdollisuuksia. Siinä tarvitsemme sinun apuasi.',
  solrUrl: '/solr/metsastysjakalastus/',
  dfpNetworkId: '135812570',
  containerName: 'eralehti',
  orderLink: 'https://tilaus.eralehti.fi/tilaus?promoName=OM_ER&promoCreative=onlinetarjous&',
  facebookLink: 'https://www.facebook.com/eralehti/',
  twitterLink: 'https://twitter.com/eralehti',
  feedLink: 'https://wp.eralehti.fi/feed',
  instaLink: 'https://www.instagram.com/eralehti/',
  siteLogo: 'https://eralehti.fi/assets/logos/Era.svg',
  backgroundVideo: '',
  paywallOptimize: 'HxVPkBQkRWGK8F8Xq_jiAA',
  defaultImage: '/assets/pictures/missing-image.png'
}
