import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import articleTypes from '../components/general/article/ArticleTypes'
import '../components/weeklyMagazine/MagazineArticle.scss'
import Paywall from '../components/widgets/Paywall'
import PropTypes from 'prop-types'
import Image from '../components/general/util/Image'
import eye from '../assets/ui/eye.svg'
import compass from '../assets/ui/compass.svg'
import book from '../assets/ui/book.svg'
import magnify from '../assets/ui/magnify.svg'
import { Link } from '../components/general/util/Links'
import Helmet from 'react-helmet'
import get from 'lodash/get'
/**
 * Displays the /tarjous page. The main image is fetched from a page in WP, the rest of the content is static.
 */
export default class Offer extends Component {
  static propTypes = {
    close: PropTypes.func,
    doneLoading: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { view: null }
    this.loadPage()
  }

  async loadPage () {
    const page = await WP.getForURL(WP.url + '/tarjous')
    this.setState({ view: page.data }, () => this.props.doneLoading && this.props.doneLoading(true))
  }

  renderHeaderImage = (video, featuredMedia, mobileHero) => {
    return video
      ? (
        <div styleName="header-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
      : <div styleName={mobileHero ? 'header-image-container not-mobile' : 'header-image-container'}>
        <Image addToGallery data={featuredMedia} isZoomed/>
      </div>
  }

  renderHeader = (article, title, video, shouldRenderHeroImage) => {
    return (
      <header styleName="header">
        {shouldRenderHeroImage ? this.renderHeaderImage(video, article.featuredMedia, article.mobileHero) : null}
        <div style={{ textAlign: 'center' }}
          styleName={` ${shouldRenderHeroImage ? (article.mobileHero ? 'on-hero-image on-hero-image-mobile' : 'on-hero-image') : ''}`}>
          {article.groupHeader && <span styleName="group-header">{article.groupHeader}</span>}
          <h1 style={{ marginTop: '1rem' }} styleName={article.capitalizeTitle ? 'capitalized center-col' : 'center-col'}>
            Suomen paras kalastus- ja retkeilylehti. <span styleName="red"> Tilaa Erä.</span></h1>
          <div styleName="ingress">
            Erä kertoo parhaista pyyntitavoista, hyvistä kohteista ja toimivimmista välineistä kaikkiin eräharrasteisiin - niin retkeilyyn, kalastukseen kun metsästykseenkin
          </div>
        </div>
      </header>
    )
  }

  renderBlocks () {
    const blocks = [
      {
        text: 'Näköislehdet vuodesta 2003',
        img: eye,
        hover: 'Lue kaikki lehdet 20 vuoden ajalta',
        url: '/arkisto/'
      },
      {
        text: 'Kattavia testejä ja vertailuja',
        img: magnify,
        hover: 'Tutkittua tietoa hankintojen perustaksi',
        url: '/kategoria/testit/'
      },
      {
        text: 'Mukana ajassa ja perinteissä',
        img: compass,
        hover: 'Uutuudet, ilmiöt ja erätaidot',
        url: '/uusimmat/'
      },
      {
        text: '12 digilehteä vuodessa',
        img: book,
        hover: 'Jokainen lehti digitaalisessa muodossa ja lisäartikkeleita',
        url: '/digilehdet/'
      },
    ]
    return <div styleName="why-order">
      <div styleName="blocks">
        {blocks.map((block, key) => <div key={key}>
          <div styleName="hover-over">
            <Link to={{ link: block.url }}>
              <img src={block.img} alt=""/>
              <p>{block.text}</p>
            </Link>
          </div>
        </div>
        )}
      </div>
    </div>
  }

  render () {
    const {
      view: article
    } = this.state

    if (!article) {
      return null
    }

    const {
      articleType,
      headerVideoMp4,
      headerVideoWebm,
      title,
    } = article

    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': 'Tarjous - Tekniikan Maailma',
      'og:description': '',
      'og:url': window.location.origin + '/tarjous',
      'og:image': get(article, 'featuredMedia.src'),
      'http:status': 200,

      // og:type is article, more tags for it:
    }
    const metaNames = {
      // Piano DMP meta fields:
      'cXenseParse:qzt-Author': '',
      'cXenseParse:qzt-Categories': '',
      'cXenseParse:qzt-CommentCount': 0,
      'cXenseParse:qzt-DateModified': '',
      'cXenseParse:qzt-DatePublish': '',
      'cXenseParse:qzt-DatePublishTime': '',
      'cXenseParse:qzt-PageTitle': 'Tarjous',
      'cXenseParse:qzt-PageType': 'Artikkeli',
      'cXenseParse:qzt-PaidContent': false,
      'cXenseParse:qzt-PostId': 0,
      'cXenseParse:qzt-PrimaryCategory': null,
      'cXenseParse:qzt-Section': null,
      'cXenseParse:qzt-Tags': '',
    }

    return <article styleName="article article-container offer" id="magazine-article">
      <Helmet>
        <title>
          {'Tarjous - Tekniikan Maailma'}
        </title>

        <link rel="canonical" href={window.location.origin + '/tarjous'}/>
        <meta name="description" content={metaTags['og:description']}/>

        {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
          ? value.map(x => <meta property={key} key={`${key}-${i}`} content={x}/>)
          : <meta property={key} key={`${key}-${i}`} content={value}/>)}
        {Object.entries(metaNames).map(([key, value], i) => Array.isArray(value)
          ? value.map(x => <meta name={key} key={`${key}-${i}`} content={x}/>)
          : <meta name={key} key={`${key}-${i}`} content={value}/>)}
      </Helmet>
      {this.renderHeader(article, title, headerVideoMp4 || headerVideoWebm, true)}
      <div styleName={`body-container ${articleType === articleTypes.MAGAZINE_COMMENT ? 'comment' : ''}`}>
        {this.renderBlocks()}
        <div styleName="center-col">
          <Paywall disableAdCoin={true} titleChildren={<h2 key="2">Pääset tilaamaan painetun
            lehden <Link to={{ link: '/tilaa/' }}>tästä linkistä</Link>. Alta voit tilata Erän digipalvelun tarjoushintaan.</h2>}/>
        </div>
      </div>
    </article>
  }
}
