import React, { Fragment } from 'react'
import {
  FacebookLogo,
  InstagramLogo,
  RssLogo,
} from '../widgets/Icons'

const SocialLinks = () => (
  <Fragment>
    <a href={window.om_constants.feedLink} target="blank">
      <RssLogo />
      <span className="screen-reader-text">RSS-syöte</span>
    </a>
    <a href={window.om_constants.facebookLink} target="blank">
      <FacebookLogo />
      <span className="screen-reader-text">{window.om_constants.abbreviation} Facebookissa</span>
    </a>
    <a href={window.om_constants.instaLink} target="blank">
      <InstagramLogo />
      <span className="screen-reader-text">{window.om_constants.abbreviation} Instagramissa</span>
    </a>
  </Fragment>
)

export default SocialLinks
