import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineCarouselLoader from '../general/util/MagazineCarouselLoader'
import StyledMagazineCardCarousel from '../weeklyMagazine/StyledMagazineCarousel'
import { CaretLeft, CaretRight } from '../widgets/Icons'

import WP from '@otavamedia/om-component-library/lib/lib/WP'

import './MagazineCarouselRow.scss'
import isEqual from 'lodash/isEqual'

class MagazineDecadeLoader extends Component {
  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    this.state = {
      magazines: [],
      loading: null,
    }
  }

  async loadMagazines () {
    this.setState({
      loading: true,
    })

    const magazines = await WP.getMagazines(`${Number(this.props.year) - 1}-12-20`, `${this.props.year}-12-31`, this.props.year, 20, true)

    this.asyncSetState({
      magazines,
      loading: false,
    }, () => this.props.onLoad(this.state))
  }

  componentDidMount () {
    this._mounted = true
    this.loadMagazines()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.year !== this.props.year) {
      this.loadMagazines()
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  render () {
    const carouselClass = []

    if (this.props.highlightSelected) carouselClass.push('highlight-selected')

    return this.state.loading !== false
      ? <MagazineCarouselLoader/>
      : <StyledMagazineCardCarousel className={carouselClass.join(' ')} initialSlide={this.props.initialSlide}
        onItemClick={this.props.onMagazineItemClick} magazines={this.state.magazines} arrowLeft={<CaretLeft color="red"/>}
        arrowRight={<CaretRight color="red"/>}/>
  }
}

MagazineDecadeLoader.propTypes = {
  year: PropTypes.number.isRequired,
  onLoad: PropTypes.func,
  onMagazineItemClick: PropTypes.func,
  initialSlide: PropTypes.number,
  highlightSelected: PropTypes.bool,
}

export default withErrorBoundary(
  MagazineDecadeLoader,
  ErrorPlaceholder(null)
)
