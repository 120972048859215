import React, { Component } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineContentsPreview from './MagazineContentsPreview'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'

import './MagazineArchive.scss'
import { logException } from '@otavamedia/om-component-library/lib/lib/errors'
import isEqual from 'lodash/isEqual'

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.85)',
  },
  content: {
    color: 'black',
    padding: 0,
    borderRadius: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }
}

class MagazineYearArchive extends Component {
  _mounted = false

  constructor (props) {
    super(props)

    this.state = {
      magazines: [],
      magazinesStatus: STATUS.NOT_REQUESTED,
      selectedMagazine: null,
      selectedMagazineStatus: STATUS.NOT_REQUESTED,
      modalOpen: !!props.number,
    }
  }

  componentDidMount () {
    this._mounted = true
    this.loadInitData()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.number) {
      this.setState({
        modalOpen: false,
      })
    } else if (!this.state.selectedMagazine || nextProps.number !== this.props.number) {
      this.loadOpenMagazineData(nextProps)
    } else if (nextProps.number) {
      this.setState({
        modalOpen: true,
      })
    }
  }

  async loadInitData () {
    await this.loadOpenMagazineData(this.props)
  }

  async loadOpenMagazineData (props) {
    try {
      const openMagazine = props.number

      if (openMagazine) {
        this.setState({
          selectedMagazineStatus: STATUS.REQUESTED,
          selectedMagazine: null,
          modalOpen: true,
        })

        const selectedMagazine = await WP.getArchivedMagazine(openMagazine, true)

        this.setState({
          selectedMagazine,
          selectedMagazineStatus: STATUS.DONE,
        })
        this.props.setRendered(true)
      }
    } catch (e) {
      console.error('Unable to load open magazine data')
      logException(e)
      this.setState({
        magazines: e,
        magazineStatus: STATUS.ERROR,
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  render () {
    const { selectedMagazine } = this.state

    return (
      <Modal
        isOpen={this.state.modalOpen}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel={selectedMagazine ? selectedMagazine.name : ''}
      >
        <div styleName="modal-header-bar">
          <button styleName="close-button" onClick={this.props.closeModal}>
            <span aria-label="Sulje">Sulje</span><span aria-label="Sulje" styleName="close-icon">×</span>
          </button>
        </div>
        <div styleName="modal-content-col">
          <div styleName="magazine-preview-container">
            <MagazineContentsPreview magazine={selectedMagazine} />
          </div>
        </div>
      </Modal>
    )
  }
}

MagazineYearArchive.propTypes = {
  view: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  nextMagazines: PropTypes.array,
  contents: PropTypes.object,
  history: PropTypes.object,
  number: PropTypes.string,
  closeModal: PropTypes.func,
  setRendered: PropTypes.func,
}

export default withErrorBoundary(
  MagazineYearArchive,
  ErrorPlaceholder(null)
)
