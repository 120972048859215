import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import styled from 'styled-components'

// import Title from '../general/util/Title'
import HighlightsLoader from '../general/util/HighlightsLoader'
import ArticleListItem from '../general/article/ArticleListItem'
import { errors as err } from '@otavamedia/om-component-library/lib/lib/errors'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'
import WhyOrder from '../widgets/WhyOrder'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import { breakpoints } from '@otavamedia/om-component-library/lib/lib/utils'
import Title from '../general/util/Title'

import './IndexHighlights.scss'

const EditorialPick = styled(ArticleListItem)`
  flex-direction: row;
  flex-wrap: wrap;
  .card-meta {
    order: 1;
    width: 100%;
    padding: 1rem;
  }
  .image-container {
    order: 2;
    padding: 0 1rem;
    width: 40%;
    border-bottom: none;
    max-height: 150px;
    margin-bottom: 1rem;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
  }
  .card-content {
    order: 3;
    padding: 0 2rem 2rem 0;
    width: 60%;
  }
`

class IndexHighlights extends Component {
  renderContents (highlights, stickies, layout) {
    let highlightIdx = 0
    let stickyIdx = 0
    return layout.map((box, idx) => {
      const boxContent = box.items.map((article, idx) => {
        let thisArticle
        if (article.contentType === 'sticky') {
          if (!stickies[stickyIdx]) {
            return <div key={idx}>[ missing sticky article ]</div> // TODO: should there be a fallback?
          }
          thisArticle = stickies[stickyIdx++]
        } else if (article.contentType === 'highlight') {
          if (!highlights[highlightIdx]) {
            return <div key={idx}>[ missing highlight article ]</div> // TODO: should there be a fallback?
          }
          thisArticle = highlights[highlightIdx++].item
        } else {
          throw err.unknownContentTypeError({ type: article.contentType })
        }

        let size = article.size
        if (size === 'auto') {
          switch (0) {
          case box.items.length % 3:
            size = 'three-columns'
            break
          case box.items.length % 2:
            size = 'sm'
            break
          default:
            size = 'four-columns'
          }
        }
        const props = {
          key: idx,
          article: thisArticle,
          size,
          notShown: { relatedArticles: !article.showRelated, ingress: !(box.boxSize === 'large') },
          sticky: article.contentType === 'sticky' && thisArticle.sticky,
          relatedArticleListLimit: article.contentType === 'sticky' ? 1 : 0,
          noImage: article.noImage,
          thumbnail: box.boxSize === 'secondary',
        }
        return box.boxSize === 'secondary'
          ? (
            <EditorialPick
              {...props}
              sizes="(max-width: 768px) 33vw, (max-width: 1440px) 25vw, 320px"
              imageSize={IMAGE_SIZE.MEDIUM_LARGE}
            />
          )
          : (
            <ArticleListItem
              {...props}
              sizes="(max-width: 768px) 100vw, (max-width: 1024px) 75vw, (max-width: 1440px) 50vw, 720px"
              imageSize={IMAGE_SIZE.MEDIUM_LARGE}
            />
          )
      })

      switch (box.boxSize) {
      case 'large':
        return <div styleName="large-col" key={idx}>{boxContent}</div>
      case 'small':
        return <div styleName="small-col" key={idx}>{boxContent}</div>
      case 'secondary':
        return <Fragment key={idx}>
          <Breakpoint maxBreakpoint={breakpoints.sm}>
            <div styleName="small-col">
              <WhyOrder/>
            </div>
          </Breakpoint>
          <div styleName="secondary-highlights-container">
            <Title text="Toimituksen valinnat" />
            <div styleName="secondary-highlights">
              <Fragment>
                {boxContent}
              </Fragment>
            </div>
          </div>
        </Fragment>
      default:
        throw err.unknownSizeError({ size: box.boxSize })
      }
    })
  }

  render () {
    const { highlights, stickies, layout, error } = this.props

    if (error) {
      throw error
    }
    return (
      <ReactPlaceholder ready={!!highlights && !!highlights.length} customPlaceholder={<HighlightsLoader />}>
        {highlights ? this.renderContents(highlights, stickies, layout) : <div/>}
      </ReactPlaceholder>
    )
  }
}

IndexHighlights.propTypes = {
  swap: PropTypes.bool,
  highlights: PropTypes.array,
  stickies: PropTypes.array,
  layout: PropTypes.array,
  error: PropTypes.any,
}

export default track({ gtmContext: ['IndexHighlights'] })(withErrorBoundary(
  IndexHighlights,
  ErrorPlaceholderBox('Artikkeleita ei saatu ladattua'),
))
