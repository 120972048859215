import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  WhatsappIcon,
  MessengerIcon,
  TwitterWhiteIcon,
  EmailWhiteIcon,
  CopyIcon,
  TwitterLogo,
  FacebookNoBgLogo,
  MailNoBgLogo,
  FacebookWhiteIcon,
  TwitterNoBgLogo,
  FacebookLogo,
} from '../../widgets/Icons'
import { Link } from '../../general/util/Links'
import './ShareButtons.scss'

class ShareButtons extends Component {
  node = null

  constructor (props) {
    super(props)
    this.state = {
      copied: false
    }
    this.node = React.createRef()
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  handleClickOutside (event) {
    if (this.node.current && !this.node.current.contains(event.target)) {
      this.setState({ popupOpen: false })
    }
  }

  copyLink (articleLinkPlain) {
    navigator.clipboard.writeText(articleLinkPlain)
    this.setState({ copied: true },
      () => setTimeout(() => {
        this.setState({ copied: false })
      }, 3000))
  }

  render () {
    const { article } = this.props
    if (!window.location.origin) {
      window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    }
    const articleLinkPlain = window.location.origin + article.link + '?' + (article.shareToken ? 'shared=' + article.shareToken : '')
    const articleLink = encodeURIComponent(articleLinkPlain)
    if (article.shareToken) {
      return (
        <div styleName="share-buttons with-bar" ref={this.node} onMouseOver={() => this.setState({ popupOpen: true })}
          onFocus={() => this.setState({ popupOpen: true })}>
          <div styleName="bar">
            <button onClick={() => this.setState({ popupOpen: true })}>Lahjoita juttu</button>
            <FacebookNoBgLogo/><TwitterNoBgLogo/><MailNoBgLogo/>
          </div>
          <div styleName={(this.state.popupOpen ? 'popup popup-open' : 'popup') + (this.props.up ? ' up' : '')}>
            <div styleName="pointer"></div>
            <div styleName="title">Anna ystäväsikin lukea tämä juttu!</div>
            <div styleName="info">Tilaajana voit päästää kenet tahansa ystäväsi lukemaan tämän jutun. Lue lisätietoja <Link
              to={{ link: '/nain-lahjoitat-eran-tilaajana-artikkeleita-ystavillesi/' }}>täältä.</Link>
            </div>
            <ul>
              <li>
                <button onClick={() => { this.copyLink(articleLinkPlain) }} styleName={this.state.copied ? 'clicked' : ''}>
                  <CopyIcon/> {this.state.copied ? 'Kopioitu' : 'Kopioi linkki'}
                </button>
              </li>
              <li>
                <a href={'mailto:?subject=Lue tämä Erä-lehden artikkeli&body=Sinua voisi kiinnostaa tämä artikkeli Erästä. Koska olen tilaaja, voit lukea sen tällä linkillä, vaikka sinulla ei olisi tilausta.%0A"' +
                  article.title + '"%0A' + articleLink } target="_blank" rel="noopener noreferrer">
                  <EmailWhiteIcon/> Sähköposti
                </a>
              </li>
              <li>
                <a href={'http://www.facebook.com/sharer/sharer.php?u=' + articleLink} target="_blank"
                  rel="noopener noreferrer">
                  <FacebookWhiteIcon/> Facebook
                </a>
              </li>
              <li>
                <a href={'http://www.twitter.com/share?text=Sinua voisi kiinnostaa tämä artikkeli Erästä. Koska olen tilaaja, voit lukea sen tällä linkillä, vaikka sinulla ei olisi tilausta.' +
                  '&url=' + articleLink} target="_blank" rel="noopener noreferrer">
                  <TwitterWhiteIcon/> X
                </a>
              </li>
              <li styleName="mobile-only">
                <a href={'fb-messenger://share/?link=' + articleLink} target="_blank" rel="noopener noreferrer">
                  <MessengerIcon/> Messenger
                </a>
              </li>
              <li>
                <a href={'https://api.whatsapp.com/send?text=Sinua voisi kiinnostaa tämä artikkeli Erästä. Koska olen tilaaja, voit lukea sen tällä linkillä, vaikka sinulla ei olisi tilausta.%0A"' +
                  article.title + '"%0A' + articleLink} target="_blank" rel="noopener noreferrer" data-action="share/whatsapp/share">
                  <WhatsappIcon/> WhatsApp
                </a>
              </li>
            </ul>
          </div>
        </div>
      )
    }

    return (
      <div styleName="share-buttons">
        <ul>
          {/* !!shareCount &&
        <li styleName="share-count"><div><b>{shareCount}</b>&nbsp;{shareCount === 1 ? 'jako' : 'jakoa'}</div></li> */}
          <li>
            <a href={'http://www.facebook.com/sharer/sharer.php?u=' + articleLink} target="_blank"
              rel="noopener noreferrer">
              <FacebookLogo/>
              <span className="screen-reader-text">Jaa Facebookissa</span>
            </a>
          </li>
          <li>
            <a href={'http://www.twitter.com/share?text=' + encodeURIComponent(
              /* Cannot include title in tweet due to special characters turned into entities */
              '\n'
            ) + '&url=' + articleLink} target="_blank" rel="noopener noreferrer">
              <TwitterLogo/>
              <span className="screen-reader-text">Jaa Twitterissä</span>
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

ShareButtons.propTypes = {
  shareCount: PropTypes.number.isRequired,
  article: PropTypes.object.isRequired,
  up: PropTypes.bool,
}

export default ShareButtons
