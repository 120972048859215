import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom'
import { connect } from 'kea'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import { stripHTML } from '@otavamedia/om-component-library/lib/lib/utils'
import Helmet from 'react-helmet'
import { Link } from '../components/general/util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import application from '@otavamedia/om-component-library/lib/kea/application'
import track from 'react-tracking'
import CatchList from '../components/widgets/CatchList'

import './CatchClub.scss'
import some from 'lodash/some'

@track({ gtmContext: ['CatchClub'] })
@connect({
  actions: [
    application, [
      'setViewData',
      'setRendered'
    ]
  ],
  props: [
    application, [
      'view',
    ],
  ]
})
  /**
   * Displays a catch club page, with page content from WP, listing of latest catch cards, and a link to the catch form.
   */
class CatchClub extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    username: PropTypes.string,
    view: PropTypes.object,
    location: PropTypes.object,
    actions: PropTypes.object,
    compact: PropTypes.bool,
  }

  loaded = {
    main: false,
    list: false,
  }

  state = {
    categories: [],
  }

  cardsPerPage = 20

  doneLoading (element) {
    if (this.loaded[element]) {
      return
    }
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.actions.setRendered(true)
    }
  }

  constructor (props) {
    super(props)

    this.loadPage(props, true)
  }

  async loadPage (props, noSetState) {
    const newState = {
      pageUrl: '/saalislista',
      formUrl: '/saalislista-lomake',
      type: 'fish',
      status: STATUS.REQUESTED
    }
    if (noSetState) {
      this.state = newState
    } else {
      this.setState({ ...newState })
    }
    const postReq = await WP.getForURL(WP.url + newState.pageUrl)

    const post = postReq.data
    this.actions.setViewData(post)
    this.setState({ status: STATUS.DONE }, () => this.doneLoading('main'))
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadPage(this.props)
    }
  }

  render () {
    const { status, formUrl, type } = this.state
    const { view } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader/>
    }

    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      ingress
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }
    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <Fragment>
        <Helmet>
          <title>
            {`${stripHTML(title)} - ${window.om_constants.siteName}`}
          </title>
          <link rel="canonical" href={fullLink}/>
          <meta name="description" content={metaTags['og:description']}/>
          {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? (
              value.map(x => (
                <meta name={key} key={`${key}-${i}`} content={x}/>
              ))
            )
            : (
              <meta name={key} key={`${key}-${i}`} content={value}/>
            ))}
        </Helmet>
        <div styleName='magazine-content'>
          <article styleName="article-container">
            <header>
              <h1><HTML>{title}</HTML></h1>
              {ingress ? <div styleName="ingress"><HTML>{ingress}</HTML></div> : null}
            </header>
            <div styleName="article-body">
              <HTML>{content}</HTML>
              <Link to={{ link: formUrl }} styleName="blue-button">Ilmoita saaliisi</Link>
            </div>
          </article>
          <div styleName="catch-list">
            {type
              ? <CatchList type={type} cardsPerPage={this.cardsPerPage} showHighlight={true} withSort={true}
                doneLoading={() => this.doneLoading('list')}/>
              : null}
          </div>
        </div>
      </Fragment>
    )
    /* eslint-enable jsx-a11y/no-onchange */
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default withRouter(CatchClub)
