import React, { Component, Fragment } from 'react'
import { connect } from 'kea'
import PropTypes from 'prop-types'
import newsletter from '@otavamedia/om-component-library/lib/kea/newsletter'
import { validateEmail } from '../../../validators'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './Newsletter.scss'

@connect({
  props: [
    newsletter, [
      'isSubmitted',
      'hasFailed',
      'isLoading',
      'isNewsletterTaken'
    ],
  ],
  actions: [
    newsletter, [
      'orderNewsletter',
      'setNewsletterTaken'
    ]
  ]
})
class Newsletter extends Component {
  canShowNewsletter = false
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      valid: false,
      username: '',
    }
    if (!props.isNewsletterTaken && !props.showAlways) {
      this.canShowNewsletter = true
      props.actions.setNewsletterTaken(this)
    }
  }

  handleInput = event => {
    const input = event.currentTarget.value
    this.setState({
      email: input,
      valid: validateEmail(input)
    })
  }

  handleUsernameInput = event => {
    const input = event.currentTarget.value
    this.setState({
      username: input,
    })
  }

  handleEnter = event => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.actions.orderNewsletter(this.state.email, this.state.username)
  }

  componentWillUnmount () {
    if (this.canShowNewsletter) {
      this.canShowNewsletter = false
      this.actions.setNewsletterTaken(null)
    }
  }

  render () {
    if (!this.canShowNewsletter && !this.props.showAlways) {
      return null
    }
    return (
      <div styleName="container">
        <div styleName="content">
          <h4 styleName="title">Tilaa Erän uutiskirje</h4>
          {this.props.isSubmitted
            ? (
              <Fragment>
                <p>Kiitos! Sähköpostiosoitteesi on lisätty postituslistallemme. Saat jatkossa tuoreimmat uutiset ja tarjoukset suoraan sähköpostiisi.</p>
                <p><a href="/">Mikäli teit tilauksen vahingossa tai tulit katumapäälle, voit peruuttaa tilauksen helposti.</a></p>
                <p>Hyviä lukuhetkiä!</p>
              </Fragment>
            )
            : (
              <Fragment>

                <p>{window.om_constants.newsletterText}</p>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div styleName="fields" onKeyDown={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  onTouchMove={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}>
                  <input type="email" className="required" onKeyDown={this.handleEnter} value={this.state.email} placeholder="Syötä sähköpostiosoitteesi" onChange={this.handleInput} />
                  <input className="required" onKeyDown={this.handleEnter} name="username" placeholder="Username" onChange={this.handleUsernameInput} />
                </div>
                {this.props.isLoading
                  ? <button disabled onClick={this.handleSubmit}>Tilausta käsitellään...</button>
                  : <button disabled={!this.state.valid} onClick={this.handleSubmit}>Tilaa uutiskirje</button>
                }
                {this.props.hasFailed ? <div styleName="error">Tilaus epäonnistui! Yritä uudelleen myöhemmin</div> : null}
              </Fragment>
            )
          }
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  isSubmitted: PropTypes.bool,
  isNewsletterTaken: PropTypes.object,
  actions: PropTypes.object,
  hasFailed: PropTypes.bool,
  isLoading: PropTypes.bool,
  showAlways: PropTypes.bool,
}

export default withErrorBoundary(
  Newsletter,
  ErrorPlaceholderBox()
)
