import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import WP from '@otavamedia/om-component-library/lib/lib/WP'
import CatchCard from '../general/comments/CatchCard'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import RenderedError from '../general/util/RenderedError'
import BestProductLoader from '../general/util/BestProductLoader'
import { CaretDown } from './Icons'
import { ShowAll } from './ShowAll'
import track from 'react-tracking'
import { Link } from '../general/util/Links'

import './CatchList.scss'

const getMax = (headers, key) => headers && headers[key]
  ? parseInt(headers[key], 10)
  : 0

@track({ gtmContext: ['CatchList'] })
  /**
   * This displays a list of CatchCards, with optional 'load more' button.
   * It can show a compact list or a bigger list.
   */
class CatchList extends Component {
  static propTypes = {
    cardsPerPage: PropTypes.number,
    withSort: PropTypes.bool,
    compact: PropTypes.bool,
    showHighlight: PropTypes.bool,
    type: PropTypes.string,
    doneLoading: PropTypes.func,
  }

  state = {
    cards: [],
    order: 'desc',
    page: 1
  }

  constructor (props) {
    super(props)
    this.state.pageUrl = '/saalislista/'
    this.state.title = 'Tuoreimmat saaliit'
  }

  async loadCards (props) {
    this.setState({ status: STATUS.REQUESTED, cards: [] })
    const response = await WP.getComments(window.location.host.includes('asteaws') ? window.om_constants.catchListIdDev : window.om_constants.catchListId, 1, this.props.cardsPerPage, this.state.order, '/era-kayttajat')

    const headers = response ? response.headers : {}
    const maxPosts = getMax(headers, 'x-wp-total')
    this.setState({ cards: response.data, status: STATUS.DONE, maxPosts }, () => this.props.doneLoading && this.props.doneLoading())
  }

  async addPage () {
    const { data: newCards } = await WP.getComments(window.location.host.includes('asteaws') ? window.om_constants.catchListIdDev : window.om_constants.catchListId, this.state.page + 1, this.props.cardsPerPage, this.state.order, '/era-kayttajat')
    if (newCards) {
      this.setState({
        page: this.state.page + 1,
        cards: this.state.cards.concat(newCards)
      })
    }
  }

  componentDidMount () {
    this.loadCards(this.props)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.type !== this.props.type) {
      this.setState({ cards: [] }, () => this.loadCards(this.props))
    }
  }

  renderCards () {
    const { cards, status } = this.state
    const { compact, type, showHighlight } = this.props

    const items = cards
      ? cards.map((p, i) => (
        <li styleName={showHighlight && !i ? 'result-item highlight' : 'result-item'} key={`${i}-${p.id}`}>
          <CatchCard data={p} compact={compact} type={type} highlight={showHighlight && !i}/>
        </li>
      ))
      : null

    const loading = status === STATUS.REQUESTED

    return (
      <Fragment>
        <ul styleName="result-list" data-loading={loading}>
          {items}
        </ul>
        {loading ? <BestProductLoader/> : null}
      </Fragment>
    )
  }

  handleFormChange = (e) => {
    const { target } = e
    this.setState({
      [target.name]: target.value,
      data: [],
      page: 1,
    }, this.loadCards)
  }

  render () {
    const { status, cards, title, pageUrl, maxPosts } = this.state
    const { withSort, compact } = this.props

    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div styleName={compact ? 'compact' : ''}>
        {withSort
          ? <form styleName="filter-form">
            <div styleName="result-filters">
              <div>
                <label htmlFor="sort">Järjestä</label>
                <div styleName="select-div">
                  <select id="sort-select" name="order" onChange={this.handleFormChange} defaultValue="desc">
                    <option value="desc">Uusin ensin</option>
                    <option value="asc">Vanhin ensin</option>
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>
              { /*
              <div>
                <label htmlFor="sort">Kategoria</label>
                <div styleName="select-div">
                  <select id="category-select" name="secondaryTerm" onChange={this.handleFormChange} value={secondaryTerm}>
                    <option value="0">Valitse</option>
                    {categories && categories.length
                      ? categories.map(x => (
                        <option value={x.term_id} key={x.term_id}>{x.name}</option>
                      ))
                      : <option value="">Kategorioita ladataan...</option>}
                  </select>
                  <div styleName="select-caret"><CaretDown color="red"/></div>
                </div>
              </div>
              */}
            </div>
          </form>
          : (status === STATUS.DONE || !compact
            ? <div styleName="header">
              <h2>{title} <Link to={{ link: '/saalislista-lomake' }} styleName="top-button">Ilmoita saalis</Link></h2>
              <ShowAll inline={true} url={pageUrl}/>
            </div>
            : null)
        }
        <section styleName="results">
          {status === STATUS.DONE
            ? this.renderCards(cards)
            : (status === STATUS.ERROR
              ? <RenderedError error={cards}/>
              : (compact ? null : <BestProductLoader/>)
            )
          }
        </section>
        <div styleName="bottom-button-area">
          <Link to={{ link: '/saalislista-lomake' }} styleName="bottom-button">Ilmoita saalis</Link>
        </div>
        {withSort
          ? <div styleName="bottom">
            {maxPosts > cards.length ? <button onClick={() => this.addPage()}>Näytä lisää</button> : null}
          </div>
          : null
        }
      </div>
    )
  }
}

export default CatchList
