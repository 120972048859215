import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import some from 'lodash/some'
import { connect } from 'kea'

import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import articleTypes from '../components/general/article/ArticleTypes'
import FlowArticle from '../components/general/article/FlowArticle'
import ArticleSwiper from '../components/widgets/ArticleSwiper'
import application from '@otavamedia/om-component-library/lib/kea/application'
import EditorsPicks from '../components/general/editorsPicks/EditorsPicks'
import Sidebar from '../components/views/Sidebar'
import { hasGoodBadShortcodes, diff, getSeason, stripHTML, breakpoints } from '@otavamedia/om-component-library/lib/lib/utils'
import MagazineArticleLoader from '../components/general/util/MagazineArticleLoader'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import weeklyMagazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import {
  AdsForDesktop,
  AdsForMobile,
  AdSlotMob2,
  AdSlotRectangle1,
  AdSlotSkyscraper1,
} from '../components/general/ads/Ads'
import TopRowAd from '../components/general/util/TopRowAd'
import CrossLinkBox from '../components/widgets/CrossLinkBox'
import MagazineArticle from '../components/weeklyMagazine/MagazineArticle'
import Image from '../components/general/util/Image'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import TopPicks from '../components/widgets/TopPicks'
import track from 'react-tracking'
import NoAdBlocker from '../components/widgets/NoAdBlocker'
import Newsletter from '../components/general/newsletter/Newsletter'
import FeatureArticle from '../components/weeklyMagazine/FeatureArticle'
import CatchList from '../components/widgets/CatchList'
import WhyOrder from '../components/widgets/WhyOrder'

import './Article.scss'

@connect({
  props: [
    application, ['view', 'isMagazineArticleView'],
    auth, ['canAccessArticle', 'premiumUser', 'loggedIn'],
    galleryLogic, ['getIndexFromImage'],
  ],
  actions: [
    weeklyMagazineStore, ['loadMagazine'],
    galleryLogic, ['openGallery', 'addImage'],
    application, ['setRendered'],
    auth, ['updateModal']
  ],
})
  /**
   * This component takes care of rendering all article pages. It renders parts of the page that are the same
   * for all article types, and then calls different article components (DefaultArticle, MagazineArticle, FeatureArticle)
   * for the differing parts.
   * For magazine articles it can also render an ArticleSwiper component, which lets the user move to the next or previous article
   * by swiping left/right. The ArticleSwiper is not used if the paywall needs to be displayed.
   */
class Article extends Component {
  loaded = {
    article: false,
    sidebar: false,
    crossLinks: false,
    editorsPicks: false
  }

  static propTypes = {
    view: PropTypes.object,
    canAccessArticle: PropTypes.func,
    getIndexFromImage: PropTypes.func,
    loggedIn: PropTypes.bool,
    history: PropTypes.object,
    doneLoading: PropTypes.func,
    actions: PropTypes.object,
    isMagazineArticleView: PropTypes.bool,
    premiumUser: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      galleryImages: [],
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    const viewDiff = diff(nextProps.view, this.props.view)
    const stateDiff = diff(nextState, this.state)
    const viewDifferent = Object.keys(viewDiff).length !== 0
    const stateDifferent = Object.keys(stateDiff).length !== 0

    if (viewDifferent) {
      return true
    } else {
      return stateDifferent
    }
  }

  /**
   * Intercepts clicks to images in WP content,
   * and opens the Gallery component.
   */
  imageInterceptor = (ev) => {
    const { target } = ev
    const isImage = !target.matches('.Icons_social-icon > img') && (
      target.matches('[class*="wp-image-"]') ||
      target.matches('header img') ||
      target.matches('.GoodBad_gallery img') ||
      target.matches('.Article_featured-image') ||
      target.matches('.MagazineArticle_main-image-container img')
    )

    if (isImage) {
      const index = this.props.getIndexFromImage(target)

      if (index === -1) return // prevent crashing

      ev.preventDefault()
      ev.stopPropagation()

      this.actions.openGallery(null, index)
    }
  }

  scrollToAnchor = event => {
    if (event.target.attributes && event.target.attributes.href && event.target.attributes.href.nodeValue[0] === '#') {
      event.preventDefault()
      document.querySelector(event.target.attributes.href.nodeValue).scrollIntoView()
    }
  }

  initTableOfContentLinks () {
    Array.from(document.querySelectorAll('.article-toc a'))
      .forEach(element => element.addEventListener('click', this.scrollToAnchor))
  }

  componentDidMount () {
    if (this.props.view.isPreview) {
      this.props.actions.loadMagazine(this.props.view.link)
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }

    if (this.articleContainer) {
      this.articleContainer.addEventListener('click', this.imageInterceptor)
      this.initTableOfContentLinks()
    }

    this.setupScripts(this.props)
  }

  componentDidUpdate (prevProps) {
    if (this.articleContainer) {
      this.articleContainer.addEventListener('click', this.imageInterceptor)
      this.initTableOfContentLinks()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.view.slug !== this.props.view.slug) {
      this.resetDoneLoading()
    }

    if (nextProps.view.id !== this.props.view.id) {
      this.setupScripts(nextProps)
    }
  }

  setupScripts = (props) => {
    const contentBody = get(props, 'view.content', '')

    const externalScripts = [
      { url: 'thinglink.me', link: '//cdn.thinglink.me/jse/embed.js', element: 'tm-thinglink-embed-src' },
      { url: 'instagram.com', link: '//platform.instagram.com/en_US/embeds.js', element: 'tm-instagram-embed-src' },
      { url: 'reddit.com', link: '//embed.redditmedia.com/widgets/platform.js', element: 'tm-reddit-embed-src' },
      { url: 'imgur.com', link: '//s.imgur.com/min/embed.js', element: 'tm-imgur-embed-src' },
      { url: 'twitter.com', link: '//platform.twitter.com/widgets.js', element: 'tm-twitter-embed-src' },
      { url: 'tiktok.com', link: '//www.tiktok.com/embed.js', element: 'tm-tiktok-embed-src' },
    ]
    externalScripts.forEach(({ element, link, url }) => {
      if (contentBody.indexOf(url) !== -1) {
        if (document.getElementById(element)) {
          const oldElement = document.getElementById(element)
          document.getElementById(element).parentNode.removeChild(oldElement)
        }
        const addScript = document.createElement('script')
        addScript.setAttribute('src', link)
        addScript.setAttribute('id', element)
        document.body.appendChild(addScript)
      }
    })
  }

  componentWillUnmount () {
    if (this.articleContainer && this.articleContainer.removeEventListener) {
      this.articleContainer.removeEventListener('click', this.imageInterceptor)
    }
    this.props.actions.setRendered(false)
  }

  renderArticleComponent = (view, pagination, type) => {
    const payWall = !this.props.canAccessArticle(view)
    const componentProps = {
      article: view,
      pagination,
      payWall,
      noManualComparisonPlacement: !hasGoodBadShortcodes(view.content),
      history: this.props.history,
      doneLoading: this.doneLoading.bind(this)
    }

    switch (type) {
    case articleTypes.SPECIAL:
      return <MagazineArticle {...componentProps} article={{ ...view, hasHeroImage: true }} />
    case articleTypes.FEATURE:
    case articleTypes.FEATUREV2:
      return <FeatureArticle {...componentProps} article={{ ...view, hasHeroImage: true }} />
    case articleTypes.MAGAZINE_ARTICLE:
    case articleTypes.MAGAZINE_COMMENT:
    case articleTypes.THEME_ARTICLE:
    case articleTypes.AD_ARTICLE:
    case undefined: // should probably never be undefined, but just in case...
      return payWall
        ? <MagazineArticle {...componentProps} article={view}/>
        : <ArticleSwiper {...componentProps} />
    case articleTypes.COMMENT:
    case articleTypes.DEFAULT:
    default:
      return this.props.isMagazineArticleView
        ? (payWall
          ? <MagazineArticle {...componentProps} article={view}/>
          : <ArticleSwiper {...componentProps} />
        )
        : <FlowArticle {...componentProps} />
    }
  }

  htmlDecode (input) {
    const e = document.createElement('div')
    e.innerHTML = input
    return !e.childNodes.length ? '' : e.childNodes[0].nodeValue
  }

  resetDoneLoading () {
    this.loaded = {
      article: false,
      sidebar: false,
      crossLinks: false,
      editorsPicks: false
    }
  }

  doneLoading (element) {
    if (this.loaded[element]) {
      return
    }
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.doneLoading(true)
    }
  }

  render () {
    const { view } = this.props
    const {
      content,
      excerpt,
      forSubscribers,
      title: encodedTitle,
      categories,
      createdDate,
      modifiedDate,
      tags,
      link,
      originalLink,
      articleType,
      id,
      hideAds,
      isAd,
      mobileHero,
      ogImage,
      ogTitle,
      ogDescription,
      author,
      commentCount,
      crossLinkingUrl
    } = view
    const showAds = !hideAds
    const title = encodedTitle ? this.htmlDecode(encodedTitle) : false
    if ((!content && !forSubscribers) || !title || !id) {
      return <MagazineArticleLoader />
    }

    const { page: paginationPage } = this.state

    const pagination = {
      changePage: (page) => this.setState({ page }),
      page: paginationPage,
    }

    const usableTitle = ogTitle && ogTitle.length ? stripHTML(ogTitle) : stripHTML(title)
    const fullLink = window.location.origin + link
    const canonicalLink = crossLinkingUrl || (window.location.origin + originalLink)
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': usableTitle,
      'og:description': ogDescription && ogDescription.length ? stripHTML(ogDescription) : stripHTML(excerpt),
      'og:url': fullLink,
      'og:image': ogImage && ogImage.length ? ogImage : get(view, 'featuredMedia.src'),

      // og:type is article, more tags for it:
      'article:tag': tags ? tags.map(x => x.name) : null,
      'article:section': categories && categories[0] ? categories[0].name : null, // THERE CAN ONLY BE ONE. And it's the primary one.
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate
    }
    const metaNames = {
      // Piano DMP meta fields:
      'cXenseParse:qzt-Author': author && author.name,
      'cXenseParse:qzt-Categories': categories && [categories.map(x => x.name).join(), ','],
      'cXenseParse:qzt-CommentCount': commentCount,
      'cXenseParse:qzt-DateModified': modifiedDate.replace('T', ' ').substr(0, 16),
      'cXenseParse:qzt-DatePublish': createdDate.substr(0, 10),
      'cXenseParse:qzt-DatePublishTime': createdDate.substr(11, 5),
      'cXenseParse:qzt-PageTitle': usableTitle,
      'cXenseParse:qzt-PageType': 'Artikkeli',
      'cXenseParse:qzt-PaidContent': forSubscribers,
      'cXenseParse:qzt-PostId': id,
      'cXenseParse:qzt-PrimaryCategory': categories && categories[0] ? categories[0].name : null,
      'cXenseParse:qzt-Section': categories && categories[0] ? categories[0].name : null,
      'cXenseParse:qzt-Tags': tags && [tags.map(x => x.name).join(), ','],
    }

    const isFlowArticle = !this.props.isMagazineArticleView && (articleType === articleTypes.DEFAULT || articleType === articleTypes.COMMENT)
    const isFeatureArticle = articleType === articleTypes.FEATURE
    const containerStyles = `article-container ${!isFlowArticle ? 'special' : ''}`
    if (view.content) {
      (() => {
        this.doneLoading('article')
      })()
    }
    if (!isFlowArticle) {
      (() => {
        this.doneLoading('crossLinks')
        this.doneLoading('sidebar')
        this.doneLoading('editorsPicks')
      })()
    }

    return <Fragment>
      <Helmet>
        <title>
          {`${usableTitle || 'Artikkelia ladataan...'} - ${window.om_constants.siteName}`}
        </title>

        <link rel="canonical" href={canonicalLink} />
        <meta name="description" content={metaTags['og:description']}/>
        {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
          ? value.map(x => <meta property={key} key={`${key}-${i}`} content={x} />)
          : <meta property={key} key={`${key}-${i}`} content={value} />)}
        {Object.entries(metaNames).map(([key, value], i) => Array.isArray(value)
          ? <meta name={key} key={`${key}-${i}`} content={value[0]} data-separator={value[1]}/>
          : <meta name={key} key={`${key}-${i}`} content={value}/>)}
      </Helmet>
      {isFlowArticle ? <TopPicks/> : null}
      {isFlowArticle ? <div styleName="full-width-col"><CatchList type={getSeason()} compact/></div> : null}
      <TopRowAd isArticleView={true} display={showAds && articleType !== articleTypes.FEATURE && !(this.props.premiumUser && window.location.pathname.indexOf('/lehti/') === 0)}/>
      <div styleName={`${containerStyles}`} ref={n => { this.articleContainer = n }}
        className={isAd ? 'Article_ad' : ''}>
        <script type="application/ld+json" id="article-ld-json">
          {'{\n' +
          '"@context": "https://schema.org",\n"@type": "Article",\n' +
          '"datePublished": "' + createdDate + '",\n"dateModified": "' + modifiedDate + '",\n' +
          '"author": "' + get(view, 'author.name') + '",\n"headline": "' + stripHTML(title).substr(0, 110) + '",\n' +
          (get(view, 'featuredMedia.src') ? '"image": "' + get(view, 'featuredMedia.src') + '",\n' : '') +
          '"publisher": {\n' +
          '  "@type": "Organization",\n  "name": "' + window.om_constants.siteName + '",\n' +
          '  "logo": {\n' +
          '    "@type": "ImageObject",\n    "url": "' + window.om_constants.siteLogo + '"\n' +
          '  }\n},\n' +
          '"mainEntityOfPage": {\n' +
          '  "@type": "WebPage",\n  "url": "' + fullLink + '"\n' +
          '}\n' +
          '}'
          }
        </script>
        {!!mobileHero && !isFeatureArticle && <div styleName="header-image-container-mobile">
          <Image addToGallery data={mobileHero} size={IMAGE_SIZE.LARGE} sizes={'100vw'} id="hero-mobile-background"/>
        </div>}
        {this.renderArticleComponent(view, pagination, articleType)}
      </div>
      <Breakpoint minBreakpoint={768}>
        {this.getSidebar(isFlowArticle, showAds, '', view)}
      </Breakpoint>
    </Fragment>
  }

  sidebarAds (AdElement) {
    return <Fragment>
      <AdsForDesktop>
        <AdElement debugName="article-sidebar" fallBackElements={[NoAdBlocker, Newsletter]}/>
      </AdsForDesktop>
      <AdsForMobile>
        <AdSlotMob2 fallBackElements={[NoAdBlocker, Newsletter]}/>
      </AdsForMobile>
    </Fragment>
  }

  getSidebar (isFlowArticle, showAds, className = '', view) {
    return isFlowArticle && (
      <aside styleName={`sidebar ${className}`}>
        <Sidebar latestMag popular filteredArticles hideAds={!showAds}
          doneLoading={() => this.doneLoading('sidebar')}>
          <div styleName="full-width">
            {showAds && this.sidebarAds(AdSlotRectangle1)}
          </div>
          <Breakpoint maxBreakpoint={breakpoints.sm}>
            <WhyOrder/>
          </Breakpoint>
          <EditorsPicks doneLoading={() => this.doneLoading('editorsPicks')}/>
          <CrossLinkBox doneLoading={() => this.doneLoading('crossLinks')}/>
          <Breakpoint minBreakpoint={breakpoints.sm}>
            <WhyOrder/>
          </Breakpoint>
          <div styleName="full-width">
            {showAds && this.sidebarAds(AdSlotSkyscraper1)}
          </div>
        </Sidebar>
      </aside>
    )
  }
}

export default track({ gtmContext: ['Article'] })(withErrorBoundary(
  Article,
  ErrorPlaceholder()
))
